import { useState } from "react";

import {
  FileTextOutlined,
  FormOutlined,
  MoreOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Input, Modal } from "antd";
import { Tabs } from "antd-mobile";
import { useFilterCompanyApplications } from "hooks/useFilterApplications";
import { Link, useNavigate, useParams } from "react-router-dom";
import { atom, useRecoilState } from "recoil";

import {
  ApplicationStatusEnum,
  ErrorMsg,
  JobStatusEnum,
  useGetJobForCompanyQuery,
} from "generated/graphql";

import { DEFAULT_COMPANY_PHOTO_URL } from "app-constants";

import ApplicationsList from "components/CardList/ApplicationsList";
import JobAdNotFound from "components/Errors/JobAdNotFound";
import SomethingWentWrong from "components/Errors/SomethingWentWrong";
import Unauthorized from "components/Errors/Unauthorized";
import Hired from "components/Hired";
import Loading from "components/Loading";
import NavBar from "components/NavBar";

import "../style.scss";

export const ongoingJobsActiveTab = atom({
  key: "ongoingJobsActiveTab",
  default: "endorsements",
});

export const finishedJobsActiveTab = atom({
  key: "finishedJobsActiveTab",
  default: "hired",
});

const JobPageMobile = (): JSX.Element => {
  const [ongoingActiveTab, setOngoingActiveTab] =
    useRecoilState(ongoingJobsActiveTab);
  const [finishedActiveTab, setFinishedActiveTab] = useRecoilState(
    finishedJobsActiveTab
  );
  const [showMore, setShowMore] = useState<boolean>(false);

  const toggleShowMore = () => {
    setShowMore((prevState) => !prevState);
  };

  const navigate = useNavigate();
  const { jobAdId } = useParams();

  const { data, error } = useGetJobForCompanyQuery({
    variables: { jobAdId: jobAdId! },
  });

  const job = data?.jobForCompany;

  const { filterText, setFilterText, filteredApplications } =
    useFilterCompanyApplications(job?.applications || []);

  const handleBack = () => {
    navigate("/jobs");
  };

  if (error) {
    const { message } = error.graphQLErrors?.[0];

    if (message === ErrorMsg.JobAdNotFound) {
      return <JobAdNotFound jobAdId={jobAdId} />;
    } else if (message === ErrorMsg.Unauthorized) {
      return (
        <Unauthorized message="Sorry, this job belongs to another company" />
      );
    }

    return <SomethingWentWrong />;
  }

  if (!job) {
    return (
      <>
        <NavBar title={null} right={<div />} />
        <Loading />
      </>
    );
  }

  const { applications, hires, jobAd } = job;

  const onChangeOngoingTab = (tab: string) => {
    setOngoingActiveTab(tab);
  };

  const onChangeFinishedTab = (tab: string) => {
    setFinishedActiveTab(tab);
  };

  const candidateApplications = filteredApplications.filter(
    (application) => !application.reference
  );
  const referredApplications = filteredApplications.filter(
    (application) => application.reference
  );

  const renderFilters = () => (
    <div className="filters-section">
      {job?.applications?.length > 0 ? (
        <Input
          className="custom-input"
          prefix={<SearchOutlined style={{ fontSize: "20px" }} />}
          value={filterText}
          suffix={<div />}
          onChange={(e) => setFilterText(e.target.value)}
        />
      ) : null}
    </div>
  );

  const renderOngoingJob = () => {
    return (
      <Tabs
        className="full-tabs-wrapper"
        activeKey={ongoingActiveTab}
        onChange={onChangeOngoingTab}
      >
        <Tabs.Tab title="Endorsements" key="endorsements">
          {renderFilters()}
          <ApplicationsList applications={referredApplications} />
        </Tabs.Tab>
        <Tabs.Tab title="Candidates" key="candidates">
          {renderFilters()}
          <ApplicationsList applications={candidateApplications} />
        </Tabs.Tab>
        <Tabs.Tab title="All" key="all">
          {renderFilters()}
          <ApplicationsList applications={filteredApplications} />
        </Tabs.Tab>
      </Tabs>
    );
  };

  const renderFinishedJob = () => {
    const rejectedApplications = applications
      ? applications.filter(
          (application) => application.status === ApplicationStatusEnum.Rejected
        )
      : [];

    return (
      <Tabs
        className="full-tabs-wrapper"
        activeKey={finishedActiveTab}
        onChange={onChangeFinishedTab}
      >
        <Tabs.Tab title="Hired" key="hired">
          {hires?.map((hire) => (
            <Hired key={hire.id} hire={hire} format={jobAd.format} />
          ))}
        </Tabs.Tab>
        <Tabs.Tab title="Rejected" key="rejected">
          <ApplicationsList applications={rejectedApplications} />
        </Tabs.Tab>
      </Tabs>
    );
  };

  return (
    <main className="desktop__main-padding">
      <>
        <Modal
          title="Basic Modal"
          visible={showMore}
          onCancel={() => setShowMore(false)}
          modalRender={() => {
            if (job.jobAd) {
              return (
                <div className="job__more-modal">
                  <img
                    src={
                      job.jobAd.company?.photoUrl || DEFAULT_COMPANY_PHOTO_URL
                    }
                    alt="Company logo"
                  />
                  <div className="job__modal-title">{job.jobAd.title}</div>
                  <div className="job__modal-subtitle">
                    {job?.jobAd?.format?.replace("_", " ")}
                  </div>
                  <div className="job__modal-subtitle">
                    {job.jobAd.location}
                    {job?.jobAd?.isRemote ? ` & Remote` : null}
                  </div>
                  <Link to={`/job/${jobAdId}`}>
                    <FileTextOutlined />
                    <span>View job</span>
                  </Link>
                  <Link to={`/job/${jobAdId}/edit`} state={{ edit: true }}>
                    <FormOutlined />
                    <span>Edit job</span>
                  </Link>
                </div>
              );
            }
            return null;
          }}
        />
        <NavBar
          title={job.jobAd.title}
          onBack={handleBack}
          right={
            <Button
              type="text"
              className="job__view-edit-job"
              onClick={toggleShowMore}
            >
              <MoreOutlined />
            </Button>
          }
        />
      </>
      {job.jobAd.status !== JobStatusEnum.Finished
        ? renderOngoingJob()
        : renderFinishedJob()}
    </main>
  );
};

export default JobPageMobile;
