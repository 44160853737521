import { Avatar } from "antd";
import { AutoCenter, Card, Empty, Tag } from "antd-mobile";
import pluralize from "pluralize";
import { Link } from "react-router-dom";
import { useResetRecoilState } from "recoil";

import { CompanyHrProfile, GetJobsForCompanyQuery } from "generated/graphql";

import { DEFAULT_COMPANY_PHOTO_URL } from "app-constants";

import {
  finishedJobsActiveTab,
  ongoingJobsActiveTab,
} from "pages/JobPage/Mobile";

import useAuth from "components/Auth/useAuth";

import { formatNumber, formatRelativeDate } from "utils";

import "./style.scss";

type Props = {
  jobs: GetJobsForCompanyQuery["jobsForCompany"];
};

const JobList = ({ jobs }: Props): JSX.Element => {
  const { userProfile } = useAuth();
  const resetOngoingActiveTab = useResetRecoilState(ongoingJobsActiveTab);
  const resetFinishedActiveTab = useResetRecoilState(finishedJobsActiveTab);

  const resetTabs = () => {
    resetOngoingActiveTab();
    resetFinishedActiveTab();
  };

  if (jobs.length === 0) {
    return (
      <Empty
        style={{ height: "100%" }}
        description={
          <div>
            <AutoCenter>
              <h3>No jobs found</h3>
            </AutoCenter>
          </div>
        }
      />
    );
  }

  return (
    <>
      {jobs.map((jobAd) => {
        const {
          id,
          title,
          format,
          location,
          currency,
          minSalary,
          maxSalary,
          company,
          date,
          numberOfApplications,
        } = jobAd;

        return (
          <Link key={id} to={`/jobs/${id}`} onClick={resetTabs}>
            <Card className="job-card-wrapper">
              {(userProfile as CompanyHrProfile)?.company?.id === id ? (
                <Avatar
                  src={company?.photoUrl || DEFAULT_COMPANY_PHOTO_URL}
                  size={60}
                />
              ) : null}

              <div className="job-card-center">
                <div className="job-card-title">{title}</div>
                <div className="job-card-subtitle">
                  {format.replace("_", " ")}
                </div>
                <div className="job-card-footer">{location}</div>
              </div>
              <div className="job-card-right">
                <Tag round>
                  {`${currency} ${formatNumber(
                    minSalary,
                    true
                  )} - ${formatNumber(maxSalary, true)}`}
                </Tag>
                <div className="job-card-footer">
                  {pluralize("candidate", numberOfApplications, true)}
                </div>
                <div className="job-card-footer">
                  {formatRelativeDate(date)}
                </div>
              </div>
            </Card>
          </Link>
        );
      })}
    </>
  );
};

export default JobList;
