import { useCallback, useRef, useState } from "react";

import { Tag } from "antd";
import { Button, Dialog, Slider } from "antd-mobile";
import { FloatingPanelRef } from "antd-mobile/es/components/floating-panel";
import { SliderValue } from "antd-mobile/es/components/slider";
import { atom, useRecoilState, useResetRecoilState } from "recoil";

import { ExperienceEnum, FieldEnum, JobTypeEnum } from "generated/graphql";

import FloatingPanel from "components/FloatingPanel";
import NavBar from "components/NavBar";

import { filtersEquals, formatNumber, getNumberOfFiltersApplied } from "utils";

import "./style.scss";

export const defaultFilters = {
  fields: [] as FieldEnum[],
  minSalary: 0,
  experiences: [] as ExperienceEnum[],
  formats: [] as JobTypeEnum[],
};

export const jobFiltersState = atom({
  key: "jobFiltersState",
  default: defaultFilters,
});

type Props = {
  setOpenFilters: (fn: VoidFunction) => void;
  setCloseFilters: (fn: VoidFunction) => void;
  onApply: (appliedFilters: typeof defaultFilters) => void;
  loading: boolean;
};

const Filters = ({
  setOpenFilters,
  setCloseFilters,
  onApply,
  loading,
}: Props): JSX.Element => {
  const [filtersApplied, setFiltersApplied] = useRecoilState(jobFiltersState);
  const [filters, setFilters] = useState<typeof defaultFilters>(filtersApplied);
  const reset = useResetRecoilState(jobFiltersState);

  const ref = useRef<FloatingPanelRef>(null);

  const resetFilters = () => {
    setFilters(defaultFilters);
    reset();
  };

  const open = useCallback(() => {
    setFilters(filtersApplied);
    ref.current?.setHeight(window.innerHeight - 85);
  }, [filtersApplied]);

  const close = useCallback(() => {
    setFilters(filtersApplied);
    ref.current?.setHeight(0);
  }, [filtersApplied]);

  const setRef = useCallback(
    (node) => {
      if (node) {
        setOpenFilters(() => open);
        setCloseFilters(() => close);
      }

      // @ts-ignore
      ref.current = node;
    },
    [setOpenFilters, setCloseFilters, open, close]
  );

  const handleToggleField = (field: FieldEnum) => {
    const isSelected = filters.fields.indexOf(field) > -1;
    const fields = isSelected
      ? filters.fields.filter((selected) => selected !== field)
      : [...filters.fields, field];

    setFilters({
      ...filters,
      fields,
    });
  };

  const handleToggleExperience = (experience: ExperienceEnum) => {
    const isSelected = filters.experiences.indexOf(experience) > -1;
    const experiences = isSelected
      ? filters.experiences.filter((selected) => selected !== experience)
      : [...filters.experiences, experience];

    setFilters({
      ...filters,
      experiences,
    });
  };

  const handleToggleFormat = (format: JobTypeEnum) => {
    const isSelected = filters.formats.indexOf(format) > -1;
    const formats = isSelected
      ? filters.formats.filter((selected) => selected !== format)
      : [...filters.formats, format];

    setFilters({
      ...filters,
      formats,
    });
  };

  const handleChangeMinSalary = (minSalary: SliderValue) => {
    setFilters({
      ...filters,
      minSalary: minSalary as number,
    });
  };

  const handleClear = () => {
    Dialog.confirm({
      content: "Are you sure you want to clear all the filters?",
      onConfirm: async () => {
        await onApplyFilters(defaultFilters);
        resetFilters();
      },
      confirmText: "Yes",
      cancelText: "Cancel",
    });
  };

  const onApplyFilters = async (newFilters: typeof defaultFilters) => {
    setFiltersApplied(newFilters);
    await onApply(newFilters);

    ref.current?.setHeight(0);
  };

  const anchors = [0, window.innerHeight - 85];

  return (
    <>
      <FloatingPanel ref={setRef} anchors={anchors}>
        <NavBar
          title="Filters"
          onBack={close}
          right={
            <Button
              color="primary"
              onClick={handleClear}
              fill="none"
              disabled={getNumberOfFiltersApplied(filters) === 0}
            >
              Clear
            </Button>
          }
        />
        <div className="job-filters__body">
          <h3>Job category</h3>
          <div className="ant-tag-group">
            {Object.values(FieldEnum).map((field) => {
              const isSelected = filters.fields.indexOf(field) > -1;
              return (
                <Tag
                  onClick={() => handleToggleField(field)}
                  key={field}
                  className={`custom-ant-tag selectable-tag ${
                    isSelected ? "ant-mobile-tag-selected" : ""
                  }`}
                >
                  {field.replaceAll("_", " ") as FieldEnum}
                </Tag>
              );
            })}
          </div>

          <br />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>Min gross salary</h3>
            <div>$ {formatNumber(filters.minSalary)}</div>
          </div>
          <div>
            <Slider
              value={filters.minSalary}
              max={200_000}
              onChange={handleChangeMinSalary}
              step={5_000}
            />
          </div>

          <br />
          <h3>Experience level</h3>
          <div className="ant-tag-group">
            {Object.values(ExperienceEnum).map((experience) => {
              const isSelected = filters.experiences.indexOf(experience) > -1;
              return (
                <Tag
                  onClick={() => handleToggleExperience(experience)}
                  key={experience}
                  className={`custom-ant-tag selectable-tag ${
                    isSelected ? "ant-mobile-tag-selected" : ""
                  }`}
                >
                  {experience.replaceAll("_", " ") as FieldEnum}
                </Tag>
              );
            })}
          </div>

          <br />
          <h3>Job type</h3>
          <div className="ant-tag-group">
            {Object.values(JobTypeEnum).map((format) => {
              const isSelected = filters.formats.indexOf(format) > -1;
              return (
                <Tag
                  onClick={() => handleToggleFormat(format)}
                  key={format}
                  className={`custom-ant-tag selectable-tag ${
                    isSelected ? "ant-mobile-tag-selected" : ""
                  }`}
                >
                  {format.replaceAll("_", " ") as FieldEnum}
                </Tag>
              );
            })}
          </div>
        </div>

        <div className="filters-apply-button">
          <Button
            color="primary"
            block
            size="large"
            onClick={() => onApplyFilters(filters)}
            loading={loading}
            disabled={loading || filtersEquals(filters, filtersApplied)}
          >
            Apply
          </Button>
        </div>
      </FloatingPanel>
    </>
  );
};

export default Filters;
