import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const Ready = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div className="dorse-landing__ready">
      <div className="header">READY TO START?</div>
      <h1>Get early access!</h1>
      <Button
        className="dorse-landing__get-started-gray-button"
        size="large"
        onClick={() => {
          navigate("/signup");
        }}
      >
        Get started
      </Button>
    </div>
  );
};

export default Ready;
