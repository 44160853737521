import { FunctionComponent, useRef, useState } from "react";

import classNames from "classnames";
import useOnClickOutside from "hooks/useOnClickOutside";
import { useNavigate } from "react-router-dom";

import "./style.scss";

type Props = {};

const BurgerMenu: FunctionComponent<Props> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const burgerRef = useRef(null);
  const burgerLineRef1 = useRef(null);
  const burgerLineRef2 = useRef(null);
  const burgerLineRef3 = useRef(null);

  const toggleMenu = () => {
    setIsOpen((prevState) => !prevState);
  };

  useOnClickOutside(
    menuRef,
    (event: any) => {
      event.stopPropagation();
      setIsOpen(false);
    },
    [burgerRef, burgerLineRef1, burgerLineRef2, burgerLineRef3]
  );

  return (
    <>
      <div
        className="dorse-landing__burger-menu-wrapper"
        onClick={toggleMenu}
        ref={burgerRef}
      >
        <div className="dorse-landing__burger-menu-line" ref={burgerLineRef1} />
        <div className="dorse-landing__burger-menu-line" ref={burgerLineRef2} />
        <div className="dorse-landing__burger-menu-line" ref={burgerLineRef3} />
      </div>
      <div
        ref={menuRef}
        className={classNames("dorse-landing__burger-menu-content", {
          "dorse-landing__burger-menu-content-open": isOpen,
        })}
      >
        <div>
          <span
            onClick={() => {
              navigate("/signin");
            }}
          >
            LOGIN
          </span>
        </div>
        <div>
          <span
            onClick={() => {
              navigate("/signup");
            }}
          >
            GET STARTED
          </span>
        </div>
      </div>
    </>
  );
};

export default BurgerMenu;
