import { Input as AntInput, Form, InputProps } from "antd";
import { RuleObject, RuleRender } from "antd/lib/form";
import { ShouldUpdate } from "rc-field-form/es/Field";

import "./style.scss";

interface Props extends InputProps {
  name: string;
  label?: string;
  required?: boolean;
  requiredMessage?: string;
  validator?: (
    rule: RuleObject,
    value: any,
    callback: (error?: string | undefined) => void
  ) => any | undefined;
  email?: boolean;
  rules?: (RuleObject | RuleRender)[];
  shouldUpdate?: ShouldUpdate<any>;
}

const FormInput = ({
  name,
  label,
  required = true,
  requiredMessage,
  validator,
  email = false,
  addonAfter,
  rules,
  shouldUpdate,
  ...inputProps
}: Props): JSX.Element => {
  const style =
    inputProps.type === "hidden"
      ? {
          display: "none",
        }
      : undefined;

  return (
    <Form.Item
      name={name}
      label={
        label ? (
          <div>
            {label}
            {required ? <sup>*</sup> : null}
          </div>
        ) : undefined
      }
      prefixCls="custom-form-input"
      shouldUpdate={shouldUpdate}
      rules={[
        {
          required,
          message: email
            ? "Email is missing or invalid"
            : requiredMessage || `${label ? label : "Field"} is missing`,
          validateTrigger: "onSubmit",
          validator,
          pattern: email
            ? /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            : undefined,
        },
        ...(rules || []),
      ]}
      style={style}
    >
      <AntInput
        className="custom-input-border-bottom"
        addonAfter={addonAfter || <div />}
        {...inputProps}
      />
    </Form.Item>
  );
};

export default FormInput;
