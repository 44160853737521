import { Dispatch, SetStateAction } from "react";

import { SearchOutlined } from "@ant-design/icons";
import { Badge, Input } from "antd";
import { RecoilState, useRecoilValue } from "recoil";

import filtersIcon from "images/icons/filters.svg";

import { defaultFilters } from "components/SearchFilters/index";

import { getNumberOfFiltersApplied } from "utils";

type Props = {
  filtersAtom: RecoilState<typeof defaultFilters>;
  open: VoidFunction;
  filterText: string;
  setFilterText: Dispatch<SetStateAction<string>>;
};

const SearchFiltersInput = ({
  filtersAtom,
  open,
  filterText,
  setFilterText,
}: Props) => {
  const filtersApplied = useRecoilValue(filtersAtom);

  const onChangeText = (e: any) => {
    setFilterText(e.target.value);
  };

  return (
    <Input
      className="custom-input"
      prefix={<SearchOutlined style={{ fontSize: "20px" }} />}
      suffix={
        <Badge count={getNumberOfFiltersApplied(filtersApplied)}>
          <img
            src={filtersIcon}
            alt="Filters"
            onClick={open}
            style={{ cursor: "pointer" }}
          />
        </Badge>
      }
      value={filterText}
      onChange={onChangeText}
    />
  );
};

export default SearchFiltersInput;
