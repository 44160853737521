import { Button } from "antd";
import { useNavigate } from "react-router-dom";

import logo from "images/dorse_logo.svg";

import BurgerMenu from "pages/Landing/BurgerMenu";

const Navbar = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <header className="dorse-landing__navbar">
      <img src={logo} alt="logo" height={20} />
      <BurgerMenu />
      <div className="flex-row dorse-landing__desktop-menu">
        <Button
          className="dorse-landing__login-button"
          type="ghost"
          color="#7E8BB6"
          shape="round"
          onClick={() => {
            navigate("/signin");
          }}
        >
          LOGIN
        </Button>
        <Button
          type="primary"
          className="gradient-background"
          shape="round"
          onClick={() => {
            navigate("/signup");
          }}
        >
          GET STARTED
        </Button>
      </div>
    </header>
  );
};

export default Navbar;
