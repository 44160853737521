import { Result } from "antd";

type Props = {
  message: string;
};

const Unauthorized = ({ message }: Props): JSX.Element => (
  <Result status="403" title="Unauthorized" subTitle={message} />
);

export default Unauthorized;
