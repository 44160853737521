import { Spin } from "antd";

import "./style.scss";

const Loading = (): JSX.Element => {
  return (
    <div className="loading-screen">
      <Spin size="large" />
    </div>
  );
};

export default Loading;
