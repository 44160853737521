type Props = {
  children: any;
  reversed?: boolean;
};

const Section = ({ children, reversed }: Props): JSX.Element => {
  return (
    <section
      className={`dorse-landing__responsive-row${reversed ? " reversed" : ""}`}
    >
      {children}
    </section>
  );
};

export default Section;
