import { Avatar } from "antd";
import { Card, Tag } from "antd-mobile";
import { Link } from "react-router-dom";

import { ApplicationDataFragment, JobAdDataFragment } from "generated/graphql";

import {
  APPLICATION_STATUS_COLORS,
  APPLICATION_STATUS_FONT_COLORS,
  DEFAULT_COMPANY_PHOTO_URL,
  DEFAULT_USER_PHOTO_URL,
} from "app-constants";

import { formatNumber, formatRelativeDate } from "utils";

import "./style.scss";

type Props = {
  applications: (ApplicationDataFragment & { jobAd?: JobAdDataFragment })[]; //GetCandidateApplicationsQuery["candidateApplications"];
  photoType?: "candidate" | "company";
  titleType?: "candidate" | "job";
  subtitleType?: "location" | "company";
  footerType?: "referred" | "jobTitle";
  tabType?: "salary" | "score";
};

const ApplicationsList = ({
  applications,
  photoType = "candidate",
  titleType = "candidate",
  subtitleType = "location",
  footerType = "referred",
  tabType = "salary",
}: Props): JSX.Element => {
  return (
    <>
      {applications.map(
        ({ id, date, fosterScore, candidate, status, reference, jobAd }) => {
          const photoUrl =
            photoType === "candidate" || !jobAd
              ? candidate.photoUrl || DEFAULT_USER_PHOTO_URL
              : jobAd.company.photoUrl || DEFAULT_COMPANY_PHOTO_URL;

          return (
            <Link key={id} to={`/application/${id}`}>
              <Card className="job-card-wrapper">
                <Avatar src={photoUrl} size={60} shape="square" />
                <div className="job-card-center">
                  <div className="job-card-title">
                    {titleType === "candidate" || !jobAd
                      ? candidate.name
                      : jobAd.title}
                  </div>
                  <div className="job-card-subtitle">
                    {subtitleType === "location" || !jobAd
                      ? candidate.location
                      : jobAd.company.name}
                  </div>
                  <div className="job-card-footer">
                    {footerType === "referred" ? (
                      reference ? (
                        `by ${reference.referrer.name}`
                      ) : (
                        <wbr />
                      )
                    ) : (
                      jobAd?.title
                    )}
                    {/*  TODO: there should be something there, or just blank?*/}
                  </div>
                </div>
                <div className="job-card-right">
                  {tabType === "salary" && jobAd ? (
                    <Tag round>{`${jobAd.currency} ${formatNumber(
                      jobAd.minSalary / 1000
                    )}-${formatNumber(jobAd.maxSalary / 1000)}k`}</Tag>
                  ) : (
                    <div className="job-application__score">{`${fosterScore}% MATCH`}</div>
                  )}
                  <Tag
                    round
                    color={APPLICATION_STATUS_COLORS[status]}
                    style={{
                      color: APPLICATION_STATUS_FONT_COLORS[status],
                    }}
                    className="uppercase bold"
                  >
                    {status.replaceAll("_", " ")}
                  </Tag>
                  <div className="job-application__date">
                    {formatRelativeDate(date)}
                  </div>
                </div>
              </Card>
            </Link>
          );
        }
      )}
    </>
  );
};

export default ApplicationsList;
