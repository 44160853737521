import { Dispatch, SetStateAction, useState } from "react";

import { Button, Modal } from "antd";
import { SliderValue } from "antd-mobile/es/components/slider";
import { useRecoilValue } from "recoil";

import { ExperienceEnum, FieldEnum, JobTypeEnum } from "generated/graphql";

import FiltersBody from "components/SearchFilters/FiltersBody";
import SearchFiltersInput from "components/SearchFilters/SearchFiltersInput";

import { getNumberOfFiltersApplied } from "utils";

import { defaultFilters, SearchFiltersProps } from "./index";
import "./style.scss";

interface SearchFiltersMobileProps extends SearchFiltersProps {
  handleToggleField: (field: FieldEnum) => void;
  handleToggleExperience: (experience: ExperienceEnum) => void;
  handleToggleFormat: (format: JobTypeEnum) => void;
  handleChangeMinSalary: (minSalary: SliderValue) => void;
  handleClear: (callback?: VoidFunction) => void;
  filters: typeof defaultFilters;
  setFilters: Dispatch<SetStateAction<typeof defaultFilters>>;
  onApplyFilters: (newFilters: typeof defaultFilters) => Promise<void>;
}

const SearchFilters = ({
  filtersAtom,
  filters,
  setFilters,
  loading,
  filterText,
  setFilterText,
  handleToggleField,
  handleToggleExperience,
  handleToggleFormat,
  handleChangeMinSalary,
  handleClear,
  onApplyFilters,
}: SearchFiltersMobileProps): JSX.Element => {
  const filtersApplied = useRecoilValue(filtersAtom);
  const [showModal, setShowModal] = useState<boolean>(false);

  const open = () => {
    setFilters(filtersApplied);
    setShowModal(true);
  };

  const close = () => {
    setFilters(filtersApplied);
    setShowModal(false);
  };

  const handleApply = async (newFilters: typeof defaultFilters) => {
    await onApplyFilters(newFilters);
    close();
  };

  return (
    <>
      <SearchFiltersInput
        filtersAtom={filtersAtom}
        open={open}
        filterText={filterText}
        setFilterText={setFilterText}
      />

      <Modal
        visible={showModal}
        className="search-filters__desktop-wrapper"
        title="Filters"
        onOk={() => handleApply(filters)}
        onCancel={close}
        footer={
          <footer className="search-filters__desktop-footer">
            {getNumberOfFiltersApplied(filters) > 0 ? (
              <Button
                color="primary"
                type="link"
                onClick={() => {
                  handleClear(() => {
                    close();
                  });
                }}
              >
                Clear
              </Button>
            ) : (
              <div />
            )}

            <Button
              type="primary"
              onClick={() => handleApply(filters)}
              loading={loading}
              disabled={loading}
            >
              Apply
            </Button>
          </footer>
        }
      >
        <FiltersBody
          filters={filters}
          handleToggleField={handleToggleField}
          handleToggleExperience={handleToggleExperience}
          handleToggleFormat={handleToggleFormat}
          handleChangeMinSalary={handleChangeMinSalary}
        />
      </Modal>
    </>
  );
};

export default SearchFilters;
