import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from "react";

import { message } from "antd";
import { GraphQLError } from "graphql";
import { useLocation, useNavigate } from "react-router-dom";

import {
  FullProfileFragment,
  Maybe,
  SignupMutation,
  useContinueWithLinkedinMutation,
  useLoginMutation,
  User,
  UserDataFragment,
  useRegeneratePasswordMutation,
  useRegeneratePasswordRequestMutation,
  useSignupMutation,
} from "generated/graphql";

import { TOKEN_KEY, TOKEN_RENEW_KEY } from "app-constants";

import { sha256 } from "utils";

type AuthContextType = {
  user: Maybe<Omit<User, "password" | "salt">>;
  setUser: Dispatch<SetStateAction<Maybe<Omit<User, "password" | "salt">>>>;
  userProfile: Maybe<FullProfileFragment> | undefined;
  setUserProfile: Dispatch<
    SetStateAction<Maybe<FullProfileFragment> | undefined>
  >;
  login: (email: string, password: string) => void;
  getLinkedinToken: () => void;
  continueWithLinkedin: (token: string) => void;
  signup: (email: string, password: string, repeatedPassword: string) => void;
  logout: () => void;
  token: string | null;
  setToken: Dispatch<SetStateAction<Maybe<string>>>;
  renewToken: string | null;
  setRenewToken: Dispatch<SetStateAction<Maybe<string>>>;
  error: Maybe<GraphQLError>;
  regeneratePasswordRequest: (email: string) => void;
  regeneratePassword: (
    regeneratePasswordRequest: string,
    password: string,
    repeatedPassword: string
  ) => void;
  isLoggedIn: boolean;
};

export const AuthContext = createContext<AuthContextType>(null!);

type AuthProviderProps = {
  children: ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [user, setUser] = useState<UserDataFragment | null>(null);
  const [userProfile, setUserProfile] = useState<
    Maybe<FullProfileFragment> | undefined
  >(null);
  const [token, setToken] = useState<string | null>(
    localStorage.getItem(TOKEN_KEY) || null
  );
  const [renewToken, setRenewToken] = useState<string | null>(
    localStorage.getItem(TOKEN_RENEW_KEY) || null
  );
  const [error, setError] = useState<GraphQLError | null>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const [fromPath, setFromPath] = useState<string | undefined>(
    // @ts-ignore
    location?.state?.from?.pathname
  );

  const setSignUp = ({ user, token }: { user: User; token: string }) => {
    setError(null);
    localStorage.setItem(TOKEN_KEY, token);
    setUser(user);
    setToken(token);
    navigate("/signup/profile");
  };

  const setLogin = ({
    user,
    profile,
    token,
  }: {
    user: User;
    profile?: Maybe<FullProfileFragment>;
    token: string;
  }) => {
    if (!profile) {
      setSignUp({ user, token });
    } else {
      setError(null);
      localStorage.setItem(TOKEN_KEY, token);
      setUser(user);
      setToken(token);
      setUserProfile(profile);
      navigate(fromPath || "/dashboard", { state: location.state });
    }
  };

  const [triggerLogin, { client }] = useLoginMutation({
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, networkError, clientErrors }) => {
      graphQLErrors.forEach((error) => {
        setError(error);
      });
      clientErrors.forEach((error) => {
        setError(error as GraphQLError);
      });
      if (networkError) {
        setError(error);
      }
    },
    onCompleted: (data) => {
      if (data.login) {
        message.success("Login successfully");
        setLogin({
          token: data.login.token,
          user: data.login.user,
          profile: data.login.profile,
        });
      }
    },
  });

  const [triggerContinueWithLinkedin] = useContinueWithLinkedinMutation({
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, networkError, clientErrors }) => {
      graphQLErrors.forEach((error) => {
        setError(error);
      });
      clientErrors.forEach((error) => {
        setError(error as GraphQLError);
      });
      if (networkError) {
        setError(error);
      }
    },
    onCompleted: (data) => {
      if (data.continueWithLinkedin) {
        if (data.continueWithLinkedin.linkedinPhotoUrl) {
          localStorage.setItem(
            `linkedin-photo-url-${data.continueWithLinkedin.user.id}`,
            data.continueWithLinkedin.linkedinPhotoUrl
          );
        }
        message.success("Login successfully");
        setLogin({
          user: data.continueWithLinkedin.user,
          profile: data.continueWithLinkedin.profile,
          token: data.continueWithLinkedin.token,
        });
      }
    },
  });

  const [triggerSignup] = useSignupMutation({
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors }) => {
      for (let error of graphQLErrors) {
        message.error(error.message);
      }
    },
    onCompleted: (data: SignupMutation) => {
      if (data.signup) {
        message.success("Sign up successfully");
        setSignUp({ user: data.signup.user, token: data.signup.token });
      }
    },
  });

  const [triggerRegeneratePasswordRequest] =
    useRegeneratePasswordRequestMutation({
      fetchPolicy: "network-only",
      onError: ({ graphQLErrors, networkError, clientErrors }) => {
        graphQLErrors.forEach((error) => {
          setError(error);
        });
        clientErrors.forEach((error) => {
          setError(error as GraphQLError);
        });
        if (networkError) {
          setError(error);
        }
      },
      onCompleted: (data) => {
        setError(null);
        if (data.regeneratePasswordRequest) {
          message.success("Email has been sent!");
        }
      },
    });

  const [triggerRegeneratePassword] = useRegeneratePasswordMutation({
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, networkError, clientErrors }) => {
      graphQLErrors.forEach((error) => {
        setError(error);
      });
      clientErrors.forEach((error) => {
        setError(error as GraphQLError);
      });
      if (networkError) {
        setError(error);
      }
    },
    onCompleted: (data) => {
      setError(null);
      if (data.regeneratePassword) {
        message.success("Password successfully recovered");
        navigate("/signin");
      }
    },
  });

  const login = async (email: string, password: string) => {
    const hashedPassword = await sha256(password);
    await triggerLogin({
      variables: { email, password: hashedPassword },
    });
  };

  const getLinkedinToken = async () => {
    const randomState = Math.random().toString().split(".")[1];
    window.location.replace(
      `https://www.linkedin.com/uas/oauth2/authorization?response_type=code&client_id=779bi6uesb43pe&scope=r_liteprofile%20r_emailaddress&state=${randomState}&redirect_uri=${
        process.env.REACT_APP_LINKEDIN_REDIRECT ||
        "http://localhost:3000/signin/linkedin"
      }`
    );
  };

  const continueWithLinkedin = async (token: string) => {
    return await triggerContinueWithLinkedin({
      variables: {
        token,
      },
    });
  };

  const signup = async (
    email: string,
    password: string,
    repeatedPassword: string
  ) => {
    const hashedPassword = await sha256(password);
    const hashedRepeatedPassword = await sha256(repeatedPassword);

    return await triggerSignup({
      variables: {
        email,
        password: hashedPassword,
        repeatedPassword: hashedRepeatedPassword,
      },
    });
  };

  const logout = async () => {
    setFromPath("/dashboard");
    localStorage.removeItem(TOKEN_KEY);
    await setToken(null);
    await setUser(null);
    await setUserProfile(null);
    await client.clearStore();
  };

  const regeneratePasswordRequest = async (email: string) => {
    const { errors } = await triggerRegeneratePasswordRequest({
      variables: { email },
    });

    if (errors) {
      throw errors;
    }
  };

  const regeneratePassword = async (
    id: string,
    password: string,
    repeatedPassword: string
  ) => {
    await triggerRegeneratePassword({
      variables: {
        regeneratePasswordRequest: id,
        password,
        repeatedPassword,
      },
    });
  };

  const value = {
    user,
    setUser,
    userProfile,
    setUserProfile,
    login,
    getLinkedinToken,
    continueWithLinkedin,
    signup,
    logout,
    token,
    setToken,
    renewToken,
    setRenewToken,
    error,
    regeneratePasswordRequest,
    regeneratePassword,
    isLoggedIn: !!(user && userProfile && token),
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
