import React, { Component, ErrorInfo, ReactNode } from "react";

import { Button } from "antd";
import { Link } from "react-router-dom";

import SomethingWentWrong from "components/Errors/SomethingWentWrong";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div style={{ textAlign: "center" }}>
          <SomethingWentWrong />
          <Button type="primary">
            <Link to="/">Back home</Link>
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
