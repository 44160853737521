import { CSSProperties, ReactNode } from "react";

import { NavBar as AntNavBar } from "antd-mobile";
import { isScrolled50Atom } from "App";
import classNames from "classnames";
import useWindowSize from "hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { ReactComponent as MessageIcon } from "images/icons/message.svg";

import DesktopTopBar from "components/TopBar/Desktop";

type Props = {
  title?: ReactNode;
  left?: string;
  right?: ReactNode;
  backTo?: string | null;
  hasBorder?: boolean;
  onBack?: VoidFunction;
  style?: CSSProperties;
  className?: string;
  sticky?: boolean;
  backArrow?: ReactNode;
  relative?: boolean;
};

const NavBar = ({
  title,
  left,
  right,
  backTo,
  onBack,
  style,
  hasBorder = false,
  sticky = false,
  className,
  backArrow,
  relative,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const isScrolled = useRecoilValue(isScrolled50Atom);

  const _onBack = () => {
    if (backTo) {
      navigate(backTo);
    } else {
      navigate(-1);
    }
  };

  if (!isMobile) {
    return <DesktopTopBar customTitle={title} />;
  }

  return (
    <div
      className={classNames("mobile-navbar", className, {
        "mobile-navbar-border-bottom": hasBorder,
        "sticky-navbar": sticky,
        "mobile-navbar-relative": relative,
        "mobile-navbar-scrolled": isScrolled,
      })}
      style={style}
    >
      <AntNavBar
        onBack={onBack || _onBack}
        right={
          right || (
            <MessageIcon
              className="mobile__message-icon"
              onClick={() => {
                navigate("/chat");
              }}
            />
          )
        }
        back={left}
        backArrow={backArrow || backTo !== null}
      >
        {title}
      </AntNavBar>
    </div>
  );
};

export default NavBar;
