import { Tag } from "antd";
import { Slider } from "antd-mobile";
import { SliderValue } from "antd-mobile/es/components/slider";

import { ExperienceEnum, FieldEnum, JobTypeEnum } from "generated/graphql";

import { formatNumber } from "utils";

import { defaultFilters } from "./index";

type Props = {
  filters: typeof defaultFilters;
  handleToggleField: (field: FieldEnum) => void;
  handleToggleExperience: (experience: ExperienceEnum) => void;
  handleToggleFormat: (format: JobTypeEnum) => void;
  handleChangeMinSalary: (minSalary: SliderValue) => void;
};

const FiltersBody = ({
  filters,
  handleToggleField,
  handleToggleExperience,
  handleToggleFormat,
  handleChangeMinSalary,
}: Props) => {
  return (
    <div className="job-filters__body">
      <h3>Job category</h3>
      <div className="ant-tag-group">
        {Object.values(FieldEnum).map((field) => {
          const isSelected = filters.fields.indexOf(field) > -1;
          return (
            <Tag
              onClick={() => handleToggleField(field)}
              key={field}
              className={`custom-ant-tag selectable-tag ${
                isSelected ? "ant-mobile-tag-selected" : ""
              }`}
            >
              {field.replaceAll("_", " ") as FieldEnum}
            </Tag>
          );
        })}
      </div>

      <br />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3>Min gross salary</h3>
        <div style={{ color: "#fff" }}>$ {formatNumber(filters.minSalary)}</div>
      </div>
      <div>
        <Slider
          value={filters.minSalary}
          max={200_000}
          onChange={handleChangeMinSalary}
          step={5_000}
        />
      </div>

      <br />
      <h3>Experience level</h3>
      <div className="ant-tag-group">
        {Object.values(ExperienceEnum).map((experience) => {
          const isSelected = filters.experiences.indexOf(experience) > -1;
          return (
            <Tag
              onClick={() => handleToggleExperience(experience)}
              key={experience}
              className={`custom-ant-tag selectable-tag ${
                isSelected ? "ant-mobile-tag-selected" : ""
              }`}
            >
              {experience.replaceAll("_", " ") as FieldEnum}
            </Tag>
          );
        })}
      </div>

      <br />
      <h3>Job type</h3>
      <div className="ant-tag-group">
        {Object.values(JobTypeEnum).map((format) => {
          const isSelected = filters.formats.indexOf(format) > -1;
          return (
            <Tag
              onClick={() => handleToggleFormat(format)}
              key={format}
              className={`custom-ant-tag selectable-tag ${
                isSelected ? "ant-mobile-tag-selected" : ""
              }`}
            >
              {format.replaceAll("_", " ") as FieldEnum}
            </Tag>
          );
        })}
      </div>
    </div>
  );
};

export default FiltersBody;
