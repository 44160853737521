import { Avatar, Card, Tag } from "antd";
import { Button, Empty } from "antd-mobile";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";

import { Hire, JobApplication, JobTypeEnum } from "generated/graphql";

import { DEFAULT_USER_PHOTO_URL } from "app-constants";

import { formatNumber, getHiredStatus } from "utils";

import "./style.scss";

type ApplicationType = Omit<JobApplication, "notes" | "job_ad" | "interviews">;

type Props = {
  // TODO: fix this type
  hire?: Pick<Hire, "yearly_salary" | "starting_date" | any> | null;
  format: JobTypeEnum;
};

const Hired = ({ hire, format }: Props): JSX.Element => {
  const navigate = useNavigate();

  if (!hire) {
    return <Empty description="Nobody was hired for this position" />;
  }

  const { starting_date, yearly_salary } = hire;
  const application = hire.application as ApplicationType;
  const {
    candidate: { id, name, photoUrl, location },
    reference,
    fosterScore,
  } = application;

  const hiredStatus = getHiredStatus(starting_date);
  const { Icon: HiredStatusIcon, label: hiredStatusLabel } = hiredStatus;

  const renderReference = () => {
    if (!reference) return null;

    const isDisabled = true;
    // TODO: "disable" should come from some back end logic

    return (
      <section className="hired__referred-by-section">
        <div>
          Endorsed by{" "}
          <Link to={`/profile/${reference.referrer.id}`}>
            {reference.referrer.name}
          </Link>
        </div>

        {isDisabled && (
          <div className="hired__disabled-payment-info">
            Payment will be enabled once the candidate has worked for at least 3
            months at the company
          </div>
        )}

        <Button block color="primary" disabled={isDisabled}>
          Pay referrer
        </Button>
      </section>
    );
  };

  return (
    <Card className="hired-card-wrapper">
      <section
        className="hired-section-header"
        onClick={() => navigate(`/profile/${id}`)}
      >
        <Avatar src={photoUrl || DEFAULT_USER_PHOTO_URL} size={80} />
        <div>
          <div className="hired-section-header__title">{name}</div>
          <div className="hired-section-header__subtitle">{location}</div>
        </div>
      </section>
      <section>
        <h3>Employment info</h3>
        <Tag
          className={`hired__employment-status color-status-${hiredStatusLabel.toLowerCase()}`}
        >
          {/*TODO: change icons*/}
          <HiredStatusIcon /> {hiredStatusLabel}
        </Tag>
      </section>
      <section className="hired__employment-info-section">
        <div className="hired__employment-info-item">
          <div>Starting date</div>
          <div>{dayjs(starting_date).format("MMM DD, YYYY")}</div>
        </div>
        <div className="hired__employment-info-item">
          <div>Employment type</div>
          <div className="capitalize">{format.replaceAll("_", " ")}</div>
        </div>
        <div className="hired__employment-info-item">
          <div>Dorse score</div>
          <div>{fosterScore}/100</div>
        </div>
        <div className="hired__employment-info-item">
          <div>Yearly salary</div>
          <div>${formatNumber(yearly_salary)}</div>
        </div>
      </section>
      {renderReference()}
    </Card>
  );
};

export default Hired;
