import { useMemo, useState } from "react";

import Fuse from "fuse.js";

import {
  GetJobsForCandidateQuery,
  GetJobsForCompanyQuery,
  GetJobsForInternalRecruiterQuery,
} from "generated/graphql";

export const useFilterJobsForCandidate = (
  jobs: GetJobsForCandidateQuery["jobsForCandidate"]
) => {
  const [filterText, setFilterText] = useState<string>("");

  const fuse = useMemo(() => {
    const options = {
      includeScore: true,
      minMatchCharLength: 2,
      threshold: 0.3,
      keys: [
        { name: "jobAd.title", weight: 4 },
        { name: "jobAd.status", weight: 2 },
        { name: "jobAd.company.name", weight: 2 },
        { name: "jobAd.location", weight: 2 },
        { name: "jobAd.requirements", weight: 2 },
        { name: "jobAd.preferred", weight: 2 },
      ],
    };

    return new Fuse(jobs, options);
  }, [jobs]);

  const filteredJobs = useMemo(() => {
    if (filterText.length < 2) {
      return jobs;
    }

    return fuse.search(filterText).map(({ item }) => item);
  }, [filterText, jobs, fuse]);

  return {
    filterText,
    setFilterText,
    filteredJobs,
  };
};

export const useFilterJobsForCompany = (
  jobs: GetJobsForCompanyQuery["jobsForCompany"]
) => {
  const [filterText, setFilterText] = useState<string>("");

  const fuse = useMemo(() => {
    const options = {
      includeScore: true,
      minMatchCharLength: 2,
      threshold: 0.3,
      keys: [
        { name: "title", weight: 4 },
        { name: "status", weight: 2 },
        { name: "company.name", weight: 2 },
        { name: "location", weight: 2 },
        { name: "requirements", weight: 2 },
        { name: "preferred", weight: 2 },
      ],
    };

    return new Fuse(jobs, options);
  }, [jobs]);

  const filteredJobs = useMemo(() => {
    if (filterText.length < 2) {
      return jobs;
    }

    return fuse.search(filterText).map(({ item }) => item);
  }, [filterText, jobs, fuse]);

  return {
    filterText,
    setFilterText,
    filteredJobs,
  };
};

export const useFilterJobsForInternalRecruiter = (
  jobs: GetJobsForInternalRecruiterQuery["jobsForInternalRecruiter"]
) => {
  const [filterText, setFilterText] = useState<string>("");

  const fuse = useMemo(() => {
    const options = {
      includeScore: true,
      minMatchCharLength: 2,
      threshold: 0.3,
      keys: [
        { name: "title", weight: 4 },
        { name: "status", weight: 2 },
        { name: "company.name", weight: 2 },
        { name: "location", weight: 2 },
        { name: "requirements", weight: 2 },
        { name: "preferred", weight: 2 },
      ],
    };

    return new Fuse(jobs, options);
  }, [jobs]);

  const filteredJobs = useMemo(() => {
    if (filterText.length < 2) {
      return jobs;
    }

    return fuse.search(filterText).map(({ item }) => item);
  }, [filterText, jobs, fuse]);

  return {
    filterText,
    setFilterText,
    filteredJobs,
  };
};
