import { FunctionComponent, useState } from "react";

import { CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";

import "./style.scss";

type Props = {
  message: string;
  buttonLabel: string;
  onClick: VoidFunction;
};

const Alert: FunctionComponent<Props> = ({ message, buttonLabel, onClick }) => {
  const [show, setShow] = useState<boolean>(true);

  if (!show) {
    return null;
  }

  return (
    <div className="alert__wrapper">
      <div className="alert__row">
        <div className="alert__title">ENDORSE A COLLAGUE, GET $10K!</div>
        <div className="alert__close">
          <CloseOutlined
            onClick={() => {
              setShow(false);
            }}
          />
        </div>
      </div>
      <div className="alert__row">
        <div>{message}</div>
        <Button className="alert__button" onClick={onClick} shape="round">
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
};

export default Alert;
