// import useConversations from "hooks/useConversations";
// import { useNavigate } from "react-router-dom";
//
// import useAuth from "components/Auth/useAuth";
import { AutoCenter, Button, Empty } from "antd-mobile";
import { useNavigate } from "react-router-dom";

import { useGetJobsForInternalRecruiterQuery } from "generated/graphql";

import JobBox from "components/JobBox";
import Loading from "components/Loading";

import "../style.scss";

const InternalRecruiterMobileDashboard = () => {
  const navigate = useNavigate();

  const { data, loading } = useGetJobsForInternalRecruiterQuery();

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return (
      <Empty
        style={{
          height: "calc(100% - 70px)",
        }}
        description={
          <AutoCenter>
            <h3 className="centered">No jobs found</h3>
            <Button
              onClick={() => {
                navigate("/add");
              }}
              color="primary"
              size="large"
            >
              Add new job
            </Button>
          </AutoCenter>
        }
      />
    );
  }

  return (
    <>
      <h3>Latest job posts</h3>
      <div className="job-box__container">
        {data.jobsForInternalRecruiter.map(({ jobAd }) => (
          <JobBox key={jobAd.id} jobAd={jobAd} />
        ))}
      </div>
    </>
  );
};

export default InternalRecruiterMobileDashboard;
