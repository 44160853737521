import { useEffect, useState } from "react";

import {
  CheckOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { message as antMessage, Form } from "antd";
import { Button } from "antd-mobile";
import { Link, Navigate, useLocation } from "react-router-dom";

import logo from "images/dorse_logo.svg";
import linkedinLogo from "images/linkedin.svg";

import useAuth from "components/Auth/useAuth";
import FormInput from "components/Form/Input";

import { isValidEmail } from "utils";

import "./style.scss";

type Values = {
  email: string;
  password: string;
};

const SignIn = (): JSX.Element => {
  const { login, getLinkedinToken, error, user } = useAuth();
  const location = useLocation();
  const [form] = Form.useForm();
  const [showEmailOk, setShowEmailOk] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const initialValues = {
    email: "",
    password: "",
  };

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  useEffect(() => {
    if (error?.message) {
      antMessage.error(error.message);
    }
  }, [error]);

  const onSubmit = async ({ email, password }: Values) => {
    if (isValidEmail(email) && password) {
      await login(email, password);
    }
  };

  const onValuesChange = (_: any, { email }: Values) => {
    if (isValidEmail(email)) {
      setShowEmailOk(true);
    } else {
      setShowEmailOk(false);
    }
  };

  if (user) {
    return <Navigate to="/profile" />;
  }

  return (
    <div>
      <div className="regular-padding">
        <div className="auth__brand-title-wrapper">
          <div className="auth__brand-title">
            <img src={logo} alt="" />
          </div>
        </div>

        <Form
          form={form}
          onFinish={onSubmit}
          initialValues={initialValues}
          onValuesChange={onValuesChange}
        >
          <FormInput
            name="email"
            label="Email"
            email
            addonAfter={
              <CheckOutlined className={!showEmailOk ? "hidden-addon" : ""} />
            }
          />

          <FormInput
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            addonAfter={
              showPassword ? (
                <EyeInvisibleOutlined onClick={toggleShowPassword} />
              ) : (
                <EyeOutlined onClick={toggleShowPassword} />
              )
            }
          />

          <Link to="/forgot" color="red">
            Forgot password?
          </Link>

          <Button
            className="auth__button-mobile gradient-background"
            color="success"
            type="submit"
            block
            size="large"
          >
            SIGN IN
          </Button>
        </Form>
        <div className="centered auth__white-text">or</div>
        <Button
          className="auth__button-social"
          color="default"
          onClick={getLinkedinToken}
          block
          size="large"
        >
          <img src={linkedinLogo} alt="" /> Continue with LinkedIn
        </Button>
        <div className="auth__white-text">
          Don't have an account yet?
          <Link to="/signup" state={{ from: location }} replace>
            Create one now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
