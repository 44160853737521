import { ForwardedRef, forwardRef, useEffect, useState } from "react";

import { FloatingPanel as AntFloatingPanel } from "antd-mobile";
import {
  FloatingPanelProps,
  FloatingPanelRef,
} from "antd-mobile/es/components/floating-panel";

import "./style.scss";

const FloatingPanel = forwardRef(
  (props: FloatingPanelProps, ref: ForwardedRef<FloatingPanelRef>) => {
    const { children, ...floatingPanelProps } = props;
    const [left, setLeft] = useState<number | "auto">(0);

    useEffect(() => {
      function handleResize() {
        setLeft(window.innerWidth < 476 ? 0 : "auto");
      }
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
      <AntFloatingPanel
        ref={ref}
        {...floatingPanelProps}
        style={{ ...floatingPanelProps.style, left }}
      >
        {children}
      </AntFloatingPanel>
    );
  }
);

export default FloatingPanel;
