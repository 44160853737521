import { useMemo, useState } from "react";

import Fuse from "fuse.js";

import {
  ApplicationDataFragment,
  GetCandidateApplicationsQuery,
} from "generated/graphql";

export const useFilterCandidateApplications = (
  applications: GetCandidateApplicationsQuery["candidateApplications"]
) => {
  const [filterText, setFilterText] = useState<string>("");

  const fuse = useMemo(() => {
    const options = {
      includeScore: true,
      minMatchCharLength: 2,
      threshold: 0.3,
      keys: [
        { name: "candidate.name", weight: 4 },
        { name: "candidate.profile.location", weight: 2 },
        { name: "reference.referrer.name", weight: 2 },
        { name: "status", weight: 2 },
        { name: "jobAd.title", weight: 2 },
        { name: "jobAd.company.name", weight: 2 },
      ],
    };

    return new Fuse(applications, options);
  }, [applications]);

  const filteredApplications = useMemo(() => {
    if (filterText.length < 2) {
      return applications;
    }

    return fuse.search(filterText).map(({ item }) => item);
  }, [filterText, applications, fuse]);

  return {
    filterText,
    setFilterText,
    filteredApplications,
  };
};

export const useFilterCompanyApplications = (
  applications: ApplicationDataFragment[]
) => {
  const [filterText, setFilterText] = useState<string>("");

  const fuse = useMemo(() => {
    const options = {
      includeScore: true,
      minMatchCharLength: 2,
      threshold: 0.3,
      keys: [
        { name: "candidate.name", weight: 4 },
        { name: "candidate.profile.location", weight: 2 },
        { name: "reference.referrer.name", weight: 2 },
        { name: "status", weight: 2 },
        { name: "jobAd.title", weight: 2 },
        { name: "jobAd.company.name", weight: 2 },
      ],
    };

    return new Fuse(applications, options);
  }, [applications]);

  const filteredApplications = useMemo(() => {
    if (filterText.length < 2) {
      return applications;
    }

    return fuse.search(filterText).map(({ item }) => item);
  }, [filterText, applications, fuse]);

  return {
    filterText,
    setFilterText,
    filteredApplications,
  };
};
