import { Result } from "antd";

const SomethingWentWrong = (): JSX.Element => (
  <Result
    status="500"
    title="Something went wrong"
    subTitle="Sorry, it seems like something went wrong on our side. We are working hard on it!"
  />
);

export default SomethingWentWrong;
