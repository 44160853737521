import { ReactNode, useEffect, useState } from "react";

import { TabBar as AntTabBar } from "antd-mobile";
import classNames from "classnames";
import useConversations from "hooks/useConversations";
import useNotifications from "hooks/useNotifications";
import useWindowSize from "hooks/useWindowSize";
import { useLocation, useNavigate } from "react-router-dom";
import { useResetRecoilState } from "recoil";

import { RoleEnum } from "generated/graphql";

import { ReactComponent as AddActiveIcon } from "images/icons/add-active.svg";
import { ReactComponent as AddIcon } from "images/icons/add.svg";
import { ReactComponent as ApplicationsActiveIcon } from "images/icons/applications-active.svg";
import { ReactComponent as ApplicationsIcon } from "images/icons/applications.svg";
import { ReactComponent as EndorsementsActiveIcon } from "images/icons/endorsements-active.svg";
import { ReactComponent as HomeActiveIcon } from "images/icons/home-active.svg";
import { ReactComponent as HomeIcon } from "images/icons/home.svg";
import { ReactComponent as JobsActiveIcon } from "images/icons/jobs-active.svg";
import { ReactComponent as JobsIcon } from "images/icons/jobs.svg";
import { ReactComponent as LogoutIcon } from "images/icons/logout.svg";
import { ReactComponent as MessageIcon } from "images/icons/message.svg";
import { ReactComponent as NotificationsActiveIcon } from "images/icons/notifications-active.svg";
import { ReactComponent as NotificationsIcon } from "images/icons/notifications.svg";
import { ReactComponent as ProfileActiveIcon } from "images/icons/profile-active.svg";
import { ReactComponent as ProfileIcon } from "images/icons/profile.svg";
import { ReactComponent as EndorsementsIcon } from "images/icons/referrals.svg";

import useAuth from "components/Auth/useAuth";
import { jobFiltersState } from "components/Filters";

import "./style.scss";

type Tab = {
  key: string;
  title: string;
  fullTitle: string;
  icon: ReactNode;
  activeIcon: ReactNode;
  badge?: ReactNode;
};

export type TabsEnum =
  | ""
  | "dashboard"
  | "jobs"
  | "add"
  | "notifications"
  | "profile"
  | "my-applications"
  | "chat"
  | "conversation"
  | "my-endorsements";

const TabBar = (): JSX.Element => {
  const { user, logout } = useAuth();
  const { isMobile } = useWindowSize();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { unread: unreadNotifications } = useNotifications(true);
  const { unread: unreadConversations } = useConversations();
  const resetJobFilters = useResetRecoilState(jobFiltersState);

  const activePath =
    (pathname?.split("/")?.find((item) => item !== "") as TabsEnum) || "";

  const [activeKey, setActiveKey] = useState<TabsEnum>(activePath);

  const homeTab = {
    key: "dashboard",
    title: activeKey === "dashboard" ? "" : "Home",
    fullTitle: "Home",
    icon: <HomeIcon />,
    activeIcon: <HomeActiveIcon />,
  };

  const jobsTab = {
    key: "jobs",
    title: activeKey === "jobs" ? "" : "Job posts",
    fullTitle: "Job posts",
    icon: <JobsIcon />,
    activeIcon: <JobsActiveIcon />,
  };

  const addTab = {
    key: "add",
    title: activeKey === "add" ? "" : "Add",
    fullTitle: "Add a job post",
    icon: <AddIcon />,
    activeIcon: <AddActiveIcon />,
  };

  const notificationsTab = {
    key: "notifications",
    title: activeKey === "notifications" ? "" : "Notifications",
    fullTitle: "Notifications",
    icon: <NotificationsIcon />,
    activeIcon: <NotificationsActiveIcon />,
    badge: unreadNotifications,
  };

  const profileTab = {
    key: "profile",
    title: activeKey === "profile" ? "" : "Profile",
    fullTitle: "Profile",
    icon: <ProfileIcon />,
    activeIcon: <ProfileActiveIcon />,
  };

  const myApplicationsTab = {
    key: "my-applications",
    title: activeKey === "my-applications" ? "" : "Applications",
    fullTitle: "My applications",
    icon: <ApplicationsIcon />,
    activeIcon: <ApplicationsActiveIcon />,
  };

  const myEndorsementsTab = {
    key: "my-endorsements",
    title: activeKey === "my-endorsements" ? "" : "Endorsements",
    fullTitle: "My endorsements",
    icon: <EndorsementsIcon />,
    activeIcon: <EndorsementsActiveIcon />,
  };

  const companyHrTabs: Tab[] = [
    homeTab,
    jobsTab,
    addTab,
    notificationsTab,
    profileTab,
  ];

  const candidateTabs: Tab[] = [
    homeTab,
    myApplicationsTab,
    myEndorsementsTab,
    notificationsTab,
    profileTab,
  ];

  useEffect(() => {
    setActiveKey(activePath);
  }, [activePath]);

  const onChangeTab = (key: string) => {
    if (key === "jobs") {
      resetJobFilters();
    }

    setActiveKey(key as TabsEnum);
    navigate(`/${key}`);
  };

  const getTabs = (): Tab[] => {
    if (user?.activeRole === RoleEnum.CompanyUser) {
      return companyHrTabs;
    } else if (user?.activeRole === RoleEnum.Candidate) {
      return candidateTabs;
    } else if (user?.activeRole === RoleEnum.InternalRecruiter) {
      return companyHrTabs;
    }
    // TODO: add staker tabs
    return companyHrTabs;
  };

  if (isMobile) {
    return (
      <AntTabBar
        activeKey={activeKey}
        onChange={onChangeTab}
        className="navbar-wrapper mobile-navbar-wrapper"
      >
        {getTabs().map((item) => (
          <AntTabBar.Item
            key={item.key}
            icon={activeKey === item.key ? item.activeIcon : item.icon}
            title={item.title}
            badge={item.badge}
          />
        ))}
      </AntTabBar>
    );
  }

  return (
    <nav className="desktop-navbar__wrapper">
      <div className="desktop-navbar__logo-wrapper"></div>
      <div className="desktop-navbar__body-wrapper">
        <div>
          {getTabs().map((item) => (
            <div
              key={item.key}
              className={classNames("desktop-navbar__item helvetica", {
                "desktop-navbar__item-selected": activeKey === item.key,
              })}
              onClick={() => {
                onChangeTab(item.key);
              }}
            >
              {item.icon} <span>{item.fullTitle}</span>
            </div>
          ))}
          <div
            className={classNames("desktop-navbar__item", {
              "desktop-navbar__item-selected": activeKey === "chat",
            })}
            onClick={() => {
              onChangeTab("chat");
            }}
          >
            <MessageIcon />
            {unreadConversations !== undefined && unreadConversations > 0 && (
              <sup />
            )}
            <span>Chat</span>
          </div>
        </div>
        <div className="desktop-navbar__item" onClick={logout}>
          <LogoutIcon />
          <span>Logout</span>
        </div>
      </div>
    </nav>
  );
};

export default TabBar;
