import { useEffect } from "react";

import { message as AntMessage } from "antd";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { ErrorMsg, useGetUserLazyQuery } from "generated/graphql";

import Loading from "components/Loading";

import useAuth from "../useAuth";

type Props = {
  children: JSX.Element;
};

const RequireAuth = ({ children }: Props): JSX.Element => {
  const { user, setUser, userProfile, setUserProfile, token, setToken } =
    useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const [getUserProfile] = useGetUserLazyQuery({
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, message, ...other }) => {
      if (message === "jwt expired") {
        AntMessage.error("Session expired");
      }
      graphQLErrors.forEach(({ message }) => {
        if (
          message // TODO:
          // message === ErrorMsg.TokenMalformed ||
          // message === ErrorMsg.TokenExpired ||
          // message === ErrorMsg.TokenNotProvided
        ) {
          setToken(null);
        } else if (message === ErrorMsg.UserNotFound) {
          // For this to happen, a token should contain a valid user id but the user not be present
          // This could be the case when a user was deleted from the database but the client still
          // keeps the token
          setToken(null);
          navigate("/signup", { state: { from: location }, replace: true });
        }
      });
      navigate("/signin", { state: { from: location }, replace: true });
    },
    // onCompleted: (data) => {
    //   // if (data.user) {
    //   //   setUser(data.user.user);
    //   //   // setToken(data.user.token);
    //   //   if (!data.user.profile) {
    //   //     navigate("/signup/profile");
    //   //   } else {
    //   //     if (data.user.profile.id) {
    //   //       setUserProfile(data.user.profile);
    //   //     }
    //   //   }
    //   // }
    // },
  });

  useEffect(() => {
    if (token && !user && !userProfile) {
      getUserProfile({
        variables: {
          renewToken: token,
        },
      }).then(({ data }) => {
        if (data?.getUser) {
          setUser(data.getUser.user);
          // setToken(data.user.token);
          if (data.getUser.profile) {
            setUserProfile(data.getUser.profile);
          }
        }
      });
    }
  }, [token, getUserProfile, user, userProfile, setUser, setUserProfile]);

  if (!token) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  if (user && userProfile && token) {
    return children;
  }

  return <Loading />;
};

export default RequireAuth;
