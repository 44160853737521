import React, {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";

import classNames from "classnames";
import useDisablePinchZoomEffect from "hooks/useDisablePinchZoomEffect";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { atom, useRecoilState, useSetRecoilState } from "recoil";

import SignIn from "pages/Authentication/SignIn";
import Dashboard from "pages/Dashboard";
import Landing from "pages/Landing";

// import Settings from "pages/Settings";
import RequireAuth from "components/Auth/RequireAuth";
import useAuth from "components/Auth/useAuth";
import Loading from "components/Loading";
import TabBar from "components/TabBar";
import TopBar from "components/TopBar";

import throttle from "utils/throttle";

import "./App.scss";

const AddJob = lazy(() => import("pages/AddJob"));
const Application = lazy(() => import("pages/Application"));
const Apply = lazy(() => import("pages/Apply"));
const ForgotPassword = lazy(
  () => import("pages/Authentication/ForgotPassword")
);
const Onboarding = lazy(() => import("pages/Authentication/Onboarding"));
const ResetPassword = lazy(() => import("pages/Authentication/ResetPassword"));

const SignInWithLinkedin = lazy(
  () => import("pages/Authentication/SignInWithLinkedin")
);
const SignUp = lazy(() => import("pages/Authentication/SignUp"));
const Chat = lazy(() => import("pages/Chat"));
const Conversation = lazy(() => import("pages/Conversation"));
const EditProfile = lazy(() => import("pages/EditProfile"));
const EmptyPage = lazy(() => import("pages/Empty"));
const Job = lazy(() => import("pages/Job"));
const JobPageMobile = lazy(() => import("pages/JobPage/Mobile"));

const JobPostsMobile = lazy(() => import("pages/JobPosts/Mobile"));
const MyApplications = lazy(() => import("pages/MyApplications"));
const MyEndorsements = lazy(() => import("pages/MyReferrals"));
const Notifications = lazy(() => import("pages/Notifications"));
const ProfileMobile = lazy(() => import("pages/Profile/Mobile"));
const ProfilePreviewMobile = lazy(() => import("pages/Profile/Mobile/Preview"));
const Refer = lazy(() => import("pages/Refer"));
const Endorse = lazy(() => import("pages/Endorse"));
const Referral = lazy(() => import("pages/Referral"));

export const scrollAtom = atom<number>({ key: "scrollHeight", default: 0 });
export const isScrolled50Atom = atom<boolean>({
  key: "isScrolled50",
  default: false,
});
export const isScrolled200Atom = atom<boolean>({
  key: "isScrolled200",
  default: false,
});
export const isScrolled400Atom = atom<boolean>({
  key: "isScrolled400",
  default: false,
});

const Profile = lazy(() => import("./pages/Settings"));

const Main = () => {
  const { isLoggedIn, token } = useAuth();
  const location = useLocation();
  const [isScrolled50, setIsScrolled50] = useRecoilState(isScrolled50Atom);
  const setIsScrolled200 = useSetRecoilState(isScrolled200Atom);
  const setIsScrolled400 = useSetRecoilState(isScrolled400Atom);

  const scrollRef = useRef<HTMLDivElement | null>(null);

  useDisablePinchZoomEffect();

  const handleScrollFunction = useCallback(() => {
    if (scrollRef.current?.scrollTop && scrollRef.current?.scrollTop > 50) {
      setIsScrolled50(true);
    } else {
      setIsScrolled50(false);
    }

    if (scrollRef.current?.scrollTop && scrollRef.current?.scrollTop > 200) {
      setIsScrolled200(true);
    } else {
      setIsScrolled200(false);
    }

    if (scrollRef.current?.scrollTop && scrollRef.current?.scrollTop > 400) {
      setIsScrolled400(true);
    } else {
      setIsScrolled400(false);
    }
  }, [setIsScrolled200, setIsScrolled400, setIsScrolled50]);

  const throttledFunction = useMemo(
    () => throttle(handleScrollFunction, 100),
    // @ts-ignore
    [handleScrollFunction]
  );

  // @ts-ignore
  const handleScroll = useMemo(() => throttledFunction, [throttledFunction]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current?.addEventListener("scroll", handleScroll);
    }

    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  if (location.pathname === "/") {
    if (!token) {
      return <Landing />;
    }
    return <Navigate to="/dashboard" />;
  }

  return (
    <div
      ref={scrollRef}
      className={classNames("main__wrapper", {
        "main__wrapper-not-logged-in": !isLoggedIn,
        "main__wrapper-scrolled": isScrolled50,
        "main__wrapper-not-logged-in-scrolled": !isLoggedIn && isScrolled50,
      })}
    >
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/signin" element={<SignIn />} />
        <Route
          path="/signin/linkedin"
          element={
            <Suspense fallback={<Loading />}>
              <SignInWithLinkedin />
            </Suspense>
          }
        />
        <Route
          path="/signup"
          element={
            <Suspense fallback={<Loading />}>
              <SignUp />
            </Suspense>
          }
        />
        <Route
          path="/signup/profile"
          element={
            <Suspense fallback={<Loading />}>
              <Onboarding />
            </Suspense>
          }
        />
        <Route
          path="/forgot"
          element={
            <Suspense fallback={<Loading />}>
              <ForgotPassword />
            </Suspense>
          }
        />
        <Route
          path="/forgot/reset/:id"
          element={
            <Suspense fallback={<Loading />}>
              <ResetPassword />
            </Suspense>
          }
        />
        <Route
          path="/dashboard"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route
          path="/profile"
          element={
            <RequireAuth>
              <Suspense fallback={<Loading />}>
                <Profile />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/profile/settings"
          element={
            <RequireAuth>
              <Suspense fallback={<Loading />}>
                <EditProfile />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/profile/preview"
          element={
            <RequireAuth>
              <Suspense fallback={<Loading />}>
                <ProfilePreviewMobile />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/profile/:profileID"
          element={
            <RequireAuth>
              <Suspense fallback={<Loading />}>
                <ProfileMobile />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/profile/user/:userId"
          element={
            <RequireAuth>
              <Suspense fallback={<Loading />}>
                <ProfileMobile />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/jobs"
          element={
            <RequireAuth>
              <Suspense fallback={<Loading />}>
                <JobPostsMobile />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/jobs/:jobAdId"
          element={
            <RequireAuth>
              <Suspense fallback={<Loading />}>
                <JobPageMobile />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/job/:jobAdId"
          element={
            <RequireAuth>
              <Suspense fallback={<Loading />}>
                <Job />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/job/:jobAdId/edit"
          element={
            <RequireAuth>
              <Suspense fallback={<Loading />}>
                <Job edit />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/job/:jobAdId/apply"
          element={
            <RequireAuth>
              <Suspense fallback={<Loading />}>
                <Apply />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/job/:jobAdId/endorse"
          element={
            <RequireAuth>
              <Suspense fallback={<Loading />}>
                <Refer />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/endorse"
          element={
            <RequireAuth>
              <Suspense fallback={<Loading />}>
                <Endorse />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/add"
          element={
            <RequireAuth>
              <Suspense fallback={<Loading />}>
                <AddJob />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/my-applications"
          element={
            <RequireAuth>
              <Suspense fallback={<Loading />}>
                <MyApplications />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/my-endorsements"
          element={
            <RequireAuth>
              <Suspense fallback={<Loading />}>
                <MyEndorsements />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/application/:applicationId"
          element={
            <RequireAuth>
              <Suspense fallback={<Loading />}>
                <Application />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/referral/:referralId"
          element={
            <RequireAuth>
              <Suspense fallback={<Loading />}>
                <Referral />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/chat"
          element={
            <RequireAuth>
              <Suspense fallback={<Loading />}>
                <Chat />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/conversation/:otherProfileId"
          element={
            <RequireAuth>
              <Suspense fallback={<Loading />}>
                <Conversation />
              </Suspense>
            </RequireAuth>
          }
        />

        <Route
          path="/notifications"
          element={
            <RequireAuth>
              <Suspense fallback={<Loading />}>
                <Notifications />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="*"
          element={
            <EmptyPage
              title={
                <>
                  <h1>404</h1>
                  <h2>Page not found</h2>
                </>
              }
            />
          }
        />
      </Routes>
    </div>
  );
};

function App() {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  return (
    <div
      className={classNames("App", {
        "app-not-logged-in": !isLoggedIn,
        "app-full-width": location.pathname === "/",
      })}
    >
      {isLoggedIn && <TopBar />}
      <Main />
      {isLoggedIn && <TabBar />}
    </div>
  );
}

export default App;
