import { Result } from "antd";

type Props = {
  jobAdId?: string;
};

const JobAdNotFound = ({ jobAdId }: Props): JSX.Element => (
  <Result
    status="404"
    title="Job not found"
    subTitle={`Sorry, this job${jobAdId ? ` (${jobAdId})` : ""} does not exist`}
  />
);

export default JobAdNotFound;
