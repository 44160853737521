import { Dispatch, SetStateAction, useState } from "react";

import { Dialog } from "antd-mobile";
import { SliderValue } from "antd-mobile/es/components/slider";
import useWindowSize from "hooks/useWindowSize";
import { RecoilState, useRecoilState, useResetRecoilState } from "recoil";

import { ExperienceEnum, FieldEnum, JobTypeEnum } from "generated/graphql";

import DesktopSearchFilter from "./Desktop";
import MobileSearchFilter from "./Mobile";

export const defaultFilters = {
  fields: [] as FieldEnum[],
  minSalary: 0,
  experiences: [] as ExperienceEnum[],
  formats: [] as JobTypeEnum[],
};

export type SearchFiltersProps = {
  filtersAtom: RecoilState<typeof defaultFilters>;
  filterText: string;
  setFilterText: Dispatch<SetStateAction<string>>;
  onApply: (appliedFilters: typeof defaultFilters) => void;
  loading: boolean;
};

const SearchFilters = (props: SearchFiltersProps): JSX.Element => {
  const { filtersAtom, onApply } = props;
  const { isMobile } = useWindowSize();
  const [filtersApplied, setFiltersApplied] = useRecoilState(filtersAtom);
  const [filters, setFilters] = useState<typeof defaultFilters>(filtersApplied);
  const reset = useResetRecoilState(filtersAtom);

  const handleReset = () => {
    reset();
    setFilters(defaultFilters);
  };

  const handleToggleField = (field: FieldEnum) => {
    const isSelected = filters.fields.indexOf(field) > -1;
    const fields = isSelected
      ? filters.fields.filter((selected) => selected !== field)
      : [...filters.fields, field];

    setFilters({
      ...filters,
      fields,
    });
  };

  const handleToggleExperience = (experience: ExperienceEnum) => {
    const isSelected = filters.experiences.indexOf(experience) > -1;
    const experiences = isSelected
      ? filters.experiences.filter((selected) => selected !== experience)
      : [...filters.experiences, experience];

    setFilters({
      ...filters,
      experiences,
    });
  };

  const handleToggleFormat = (format: JobTypeEnum) => {
    const isSelected = filters.formats.indexOf(format) > -1;
    const formats = isSelected
      ? filters.formats.filter((selected) => selected !== format)
      : [...filters.formats, format];

    setFilters({
      ...filters,
      formats,
    });
  };

  const handleChangeMinSalary = (minSalary: SliderValue) => {
    setFilters({
      ...filters,
      minSalary: minSalary as number,
    });
  };

  const handleClear = (callback?: VoidFunction) => {
    Dialog.confirm({
      content: "Are you sure you want to clear all the filters?",
      onConfirm: async () => {
        await onApplyFilters(defaultFilters);
        handleReset();
        if (callback) {
          callback();
        }
      },
      confirmText: "Yes",
      cancelText: "Cancel",
    });
  };

  const onApplyFilters = async (newFilters: typeof defaultFilters) => {
    setFiltersApplied(newFilters);
    await onApply(newFilters);
  };

  const childProps = {
    handleToggleField,
    handleToggleExperience,
    handleToggleFormat,
    handleChangeMinSalary,
    handleClear,
    filters,
    onApplyFilters,
    setFilters,
  };

  if (isMobile) {
    return <MobileSearchFilter {...props} {...childProps} />;
  } else return <DesktopSearchFilter {...props} {...childProps} />;
};

export default SearchFilters;
