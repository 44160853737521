import logo from "images/dorse_logo.svg";

const Footer = (): JSX.Element => {
  return (
    <header className="dorse-landing__footer">
      <div>email: lfg@dorse.io</div>
      <img src={logo} alt="logo" height={20} />
      <div>2022 All right reserved</div>
    </header>
  );
};

export default Footer;
