import { AutoCenter, Button, Empty } from "antd-mobile";
import { useNavigate } from "react-router-dom";

import {
  OrderEnum,
  SortJobsByKeyEnum,
  useGetJobsForCompanyQuery,
} from "generated/graphql";

import JobList from "components/CardList/JobList";
import Loading from "components/Loading";

import "../style.scss";

const CompanyMobileDashboard = () => {
  const navigate = useNavigate();
  const { data, loading } = useGetJobsForCompanyQuery({
    fetchPolicy: "network-only",
    variables: {
      sortBy: { key: SortJobsByKeyEnum.Date, order: OrderEnum.Desc },
    },
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <h3>Latest job posts</h3>
      {data?.jobsForCompany ? (
        <JobList jobs={data.jobsForCompany.slice(0, 5)} />
      ) : (
        <Empty
          description={
            <AutoCenter>
              <h3 className="centered">No jobs found</h3>
              <Button
                onClick={() => {
                  navigate("/add");
                }}
                color="primary"
                size="large"
              >
                Add new job
              </Button>
            </AutoCenter>
          }
        />
      )}
    </>
  );
};

export default CompanyMobileDashboard;
