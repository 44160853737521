import { CheckOutlined, ClockCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import { Message } from "generated/graphql";

export const formatNumber = (number?: number, k: boolean = false): string => {
  if (!number) {
    return "0";
  }
  const num = k ? number / 1000 : number;
  return `${num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${
    k ? "k" : ""
  }`;
};

export const formatDate = (date: string): string => {
  // TODO: fix when date = 2022-04-20 21:56:24.415239 +0000 UTC
  // Working:              2022-04-22 11:36:45.988712 -0500 CDT
  console.log(date);
  const d = new Date(date);
  console.log("d", d);
  const now = new Date();
  console.log(now);
  if (now.getTime() - d.getTime() <= 3_600_000) {
    return `${dayjs(d).fromNow(now.getTime() - d.getTime() <= 44_000)}`;
  }
  return dayjs(d).calendar(null, {
    sameDay: "h:mm A",
    nextDay: "[Tomorrow at] h:mm A",
    nextWeek: "ddd [at] h:mm A",
    lastDay: "[Yesterday]",
    lastWeek: "ddd",
    sameElse: "DD/MM/YYYY",
  });
};

export const formatRelativeDate = (date: string): string => {
  return dayjs(date).fromNow();
};

export const getUnreadMessages = (messages: Partial<Message>[]): number => {
  let count = 0;

  for (let i = 0; i < messages.length; i++) {
    const message = messages[i];
    if (!message.read) {
      count += 1;
    }
  }

  return count;
};

export const getNumberOfFiltersApplied = (
  filters: Record<string, any[] | number>
): number => {
  let count = 0;

  Object.values(filters).forEach((value) => {
    if (typeof value !== "number" && value.length > 0) {
      count += 1;
    } else if (value > 0) {
      count += 1;
    }
  });

  return count;
};

/***
 * Deep comparison of filter objects
 * @param filters1
 * @param filters2
 */
export const filtersEquals = (
  filters1: Record<string, any[] | number>,
  filters2: Record<string, any[] | number>
): boolean => {
  if (typeof filters1 !== typeof filters2) {
    return false;
  }

  let equal = true;

  Object.keys(filters1).forEach((key) => {
    if (typeof filters1[key] === "number") {
      if (filters1[key] !== filters2[key]) {
        equal = false;
      }
    } else {
      const value1 = filters1[key] as string[];
      const value2 = filters2[key] as string[];

      value1.forEach((item1) => {
        if (!value2.includes(item1)) {
          equal = false;
        }
      });
    }
  });

  return equal;
};

const MS_PER_DAY = 1_000 * 60 * 60 * 24;

export const getHiredStatus = (
  startDate: string
): { Icon: typeof ClockCircleOutlined; label: string } => {
  const now = new Date().getTime();
  const startTime = new Date(startDate).getTime();
  const diff = now - startTime;
  const days = Math.floor(diff / MS_PER_DAY);

  // TODO: implement logic properly
  if (days <= 90) {
    return {
      Icon: ClockCircleOutlined,
      label: "Onboarding",
    };
  }

  return {
    Icon: CheckOutlined,
    label: "Working",
  };
};

export const sha256 = async (message: string): Promise<string> => {
  const msgUint8 = new TextEncoder().encode(message);
  const hashBuffer = await window.crypto.subtle.digest("SHA-256", msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
};

export const isValidEmail = (email: string): boolean => {
  return !!(
    email &&
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  );
};

export const pickRandom = (arr: any[]) => {
  if (arr.length === 0) {
    return null;
  }
  return arr[Math.floor(Math.random() * arr.length)];
};
