import { Dispatch, SetStateAction, useCallback, useRef } from "react";

import { Button } from "antd-mobile";
import { FloatingPanelRef } from "antd-mobile/es/components/floating-panel";
import { SliderValue } from "antd-mobile/es/components/slider";
import { useRecoilValue } from "recoil";

import { ExperienceEnum, FieldEnum, JobTypeEnum } from "generated/graphql";

import { ReactComponent as BackIcon } from "images/icons/back.svg";

import FloatingPanel from "components/FloatingPanel";
import FiltersBody from "components/SearchFilters/FiltersBody";
import SearchFiltersInput from "components/SearchFilters/SearchFiltersInput";

import { getNumberOfFiltersApplied } from "utils";

import { defaultFilters, SearchFiltersProps } from "./index";
import "./style.scss";

interface SearchFiltersMobileProps extends SearchFiltersProps {
  handleToggleField: (field: FieldEnum) => void;
  handleToggleExperience: (experience: ExperienceEnum) => void;
  handleToggleFormat: (format: JobTypeEnum) => void;
  handleChangeMinSalary: (minSalary: SliderValue) => void;
  handleClear: (callback?: VoidFunction) => void;
  filters: typeof defaultFilters;
  setFilters: Dispatch<SetStateAction<typeof defaultFilters>>;
  onApplyFilters: (newFilters: typeof defaultFilters) => Promise<void>;
}

const SearchFilters = ({
  filtersAtom,
  filters,
  setFilters,
  loading,
  filterText,
  setFilterText,
  handleToggleField,
  handleToggleExperience,
  handleToggleFormat,
  handleChangeMinSalary,
  handleClear,
  onApplyFilters,
}: SearchFiltersMobileProps): JSX.Element => {
  const filtersApplied = useRecoilValue(filtersAtom);

  const ref = useRef<FloatingPanelRef>(null);

  const open = useCallback(() => {
    setFilters(filtersApplied);
    ref.current?.setHeight(window.innerHeight - 85);
  }, [filtersApplied, setFilters]);

  const close = useCallback(() => {
    setFilters(filtersApplied);
    ref.current?.setHeight(0);
  }, [filtersApplied, setFilters]);

  const setRef = useCallback((node) => {
    // @ts-ignore
    ref.current = node;
  }, []);

  const handleApply = async (newFilters: typeof defaultFilters) => {
    await onApplyFilters(newFilters);
    ref.current?.setHeight(0);
  };

  const anchors = [0, window.innerHeight - 85];

  return (
    <>
      <SearchFiltersInput
        filtersAtom={filtersAtom}
        open={open}
        filterText={filterText}
        setFilterText={setFilterText}
      />

      <FloatingPanel ref={setRef} anchors={anchors}>
        <div className="search-filters__mobile-navbar">
          <div className="search-filters__mobile-navbar-left">
            <BackIcon onClick={close} />
          </div>
          <div className="search-filters__mobile-navbar-title">Filters</div>
          <Button
            className="search-filters__mobile-navbar-right"
            color="primary"
            onClick={() => {
              handleClear(() => {
                ref.current?.setHeight(0);
              });
            }}
            fill="none"
            disabled={getNumberOfFiltersApplied(filters) === 0}
          >
            Clear
          </Button>
        </div>

        <FiltersBody
          filters={filters}
          handleToggleField={handleToggleField}
          handleToggleExperience={handleToggleExperience}
          handleToggleFormat={handleToggleFormat}
          handleChangeMinSalary={handleChangeMinSalary}
        />

        <div className="filters-apply-button">
          <Button
            color="primary"
            block
            size="large"
            onClick={() => handleApply(filters)}
            loading={loading}
            disabled={loading}
          >
            Apply
          </Button>
        </div>
      </FloatingPanel>
    </>
  );
};

export default SearchFilters;
