import { isScrolled50Atom } from "App";
import classNames from "classnames";
import usePageTitle from "hooks/usePageTitle";
import useWindowSize from "hooks/useWindowSize";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { ReactComponent as BackIcon } from "images/icons/back.svg";
import { ReactComponent as MessageIcon } from "images/icons/message.svg";

import "./style.scss";

const MobileTopBar = (): JSX.Element => {
  const navigate = useNavigate();
  const { isMobile, loadingIsMobile } = useWindowSize();
  const isScrolled = useRecoilValue(isScrolled50Atom);

  const location = useLocation();

  const { title, subtitle } = usePageTitle();

  const renderLeft = () => {
    // @ts-ignore
    if (subtitle || location?.state?.prevPath) {
      return (
        <BackIcon
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(-1);
          }}
        />
      );
    }
  };

  const renderRight = () => {
    const isActive =
      location.pathname.includes("conversation") ||
      location.pathname.includes("chat");
    // TODO: use different icon when active
    // TODO: maybe allow starting new conversation?
    const ChatIcon = isActive ? MessageIcon : MessageIcon;
    return (
      <ChatIcon
        className="mobile__message-icon"
        onClick={() => {
          navigate("/chat");
        }}
      />
    );
  };

  if (loadingIsMobile) {
    return <div />;
  }

  if (isMobile) {
    return (
      <nav
        className={classNames("mobile-topbar__wrapper", {
          "mobile-topbar__wrapper-scrolled": isScrolled,
        })}
      >
        <div className="mobile-topbar__left">{renderLeft()}</div>
        <div className="topbar__title">
          <div className="topbar__title-text">{subtitle || title}</div>
        </div>
        <section className="mobile-topbar__right">{renderRight()}</section>
      </nav>
    );
  }

  return <></>;
};

export default MobileTopBar;
