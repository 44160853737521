import { Tag } from "antd-mobile";
import { useNavigate } from "react-router-dom";

import { JobForCompanyJobAdFragment } from "generated/graphql";

import { JOB_STATUS_COLORS } from "app-constants";

import { formatRelativeDate } from "utils";

import "./style.scss";

type Props = {
  jobAd: JobForCompanyJobAdFragment;
};

const JobBox = ({ jobAd }: Props): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div
      className="job-box__wrapper"
      onClick={() => {
        navigate(`/job/${jobAd.id}`);
      }}
    >
      <header>
        <div className="job-box__header">{jobAd.format.replace("_", " ")}</div>
        <h4 className="job-box__title">
          <b>{jobAd.title}</b>
        </h4>
        <h5>{jobAd.company.name}</h5>
        <h5 className="job-box__location">{jobAd.location}</h5>

        <Tag color={JOB_STATUS_COLORS[jobAd.status]} className="status-tag">
          {jobAd.status.toUpperCase()}
        </Tag>

        <h5 style={{ marginTop: "8px" }}>
          {jobAd.numberOfApplications} candidates
        </h5>
      </header>
      <h5>{formatRelativeDate(jobAd.date)}</h5>
    </div>
  );
};

export default JobBox;
