import { MessageOutlined } from "@ant-design/icons";
import { Avatar, Badge } from "antd";
import useConversations from "hooks/useConversations";
import { useNavigate } from "react-router-dom";

import { RoleEnum } from "generated/graphql";

import { DEFAULT_USER_PHOTO_URL } from "app-constants";

import CandidateMobileDashboard from "pages/Dashboard/CandidateMobile";
import CompanyMobileDashboard from "pages/Dashboard/CompanyMobile";
import InternalRecruiterMobileDashboard from "pages/Dashboard/InternalRecruiterMobile";

import useAuth from "components/Auth/useAuth";
import NavBar from "components/NavBar";

import "./style.scss";

const Dashboard = () => {
  const { userProfile, user } = useAuth();
  const navigate = useNavigate();

  const photoUrl = userProfile?.photoUrl;

  const { unread: unreadConversations } = useConversations();

  const onOpenChat = () => {
    navigate("/chat");
  };

  const onClickAvatar = () => {
    navigate("/profile");
  };

  const renderDashboard = () => {
    if (user?.activeRole === RoleEnum.CompanyUser) {
      return <CompanyMobileDashboard />;
    } else if (user?.activeRole === RoleEnum.Candidate) {
      return <CandidateMobileDashboard />;
    } else if (user?.activeRole === RoleEnum.InternalRecruiter) {
      return <InternalRecruiterMobileDashboard />;
    }
    return <div>TODO: staker and internal recruiter dashboard</div>;
  };

  return (
    <main>
      <NavBar
        className="dashboard__navbar-wrapper"
        left="Dashboard"
        right={
          <div className="navbar-right-wrapper">
            <Badge dot count={unreadConversations}>
              <MessageOutlined
                style={{ fontSize: "24px", color: "#fff" }}
                onClick={onOpenChat}
              />
            </Badge>
            <div onClick={onClickAvatar}>
              <Avatar src={photoUrl || DEFAULT_USER_PHOTO_URL} size={40} />
            </div>
          </div>
        }
        backArrow={null}
        backTo={null}
      />

      <main className="desktop__main-padding regular-padding">
        {renderDashboard()}
      </main>
    </main>
  );
};

export default Dashboard;
