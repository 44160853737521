import { useMemo } from "react";

import {
  GetConversationsQuery,
  useGetConversationsQuery,
} from "generated/graphql";

import useAuth from "components/Auth/useAuth";

const useConversations = (): {
  conversations?: GetConversationsQuery["conversations"];
  loading: boolean;
  unread?: number;
  refetch: VoidFunction;
} => {
  const { userProfile } = useAuth();
  const { data, loading, refetch } = useGetConversationsQuery({
    fetchPolicy: "network-only",
  });

  // useEffect(() => {
  //   subscribeToMore({
  //     document: GetConversationUpdatedDocument,
  //     onError: (e) => {
  //       console.log(e);
  //     },
  //     updateQuery: (prev, { subscriptionData }) => {
  //       if (!subscriptionData.data) return prev;
  //
  //       // @ts-ignore
  //       const newConversation = subscriptionData.data.conversationUpdated;
  //
  //       let exists = prev.conversations.find(
  //         (conversation) => conversation.with.id === newConversation.with.id
  //       );
  //
  //       if (exists) {
  //         const updatedConversations = prev.conversations.map(
  //           (conversation) => {
  //             if (conversation.with.id === newConversation.with.id) {
  //               return newConversation;
  //             }
  //             return conversation;
  //           }
  //         );
  //
  //         return Object.assign({}, prev, {
  //           conversations: updatedConversations,
  //         });
  //       } else {
  //         return Object.assign({}, prev, {
  //           conversations: [newConversation, ...prev.conversations],
  //         });
  //       }
  //     },
  //   });
  // }, [subscribeToMore]);

  const unread = useMemo(() => {
    if (data?.conversations) {
      return data.conversations.reduce((acc, curr) => {
        const hasUnreadMessage = !!curr.messages.find(
          (message) => message.from.id !== userProfile?.id && !message.read
        );

        if (!hasUnreadMessage) {
          return acc;
        }

        return acc + 1;
      }, 0);
    }
    return 0;
  }, [data, userProfile?.id]);

  return { conversations: data?.conversations, loading, unread, refetch };
};

export default useConversations;
