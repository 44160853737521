import { DetailedHTMLProps, ImgHTMLAttributes } from "react";

interface Props
  extends DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {}

const Image = ({ src, alt, ...imageProps }: Props): JSX.Element => {
  return (
    <div className="dorse-landing__image">
      <img src={src} alt={alt} {...imageProps} />
    </div>
  );
};

export default Image;
