import { useFilterJobsForCandidate } from "hooks/useFilterJobs";
import { useNavigate } from "react-router-dom";
import { atom } from "recoil";

import { useGetJobsForCandidateQuery } from "generated/graphql";

import Alert from "components/Alert";
import JobsForCandidateList from "components/CardList/JobsForCandidateList";
import { defaultFilters } from "components/Filters";
import Loading from "components/Loading";
import SearchFilters from "components/SearchFilters";

import "../style.scss";

export const candidateDashboardFiltersState = atom({
  key: "candidateDashboardFiltersState",
  default: defaultFilters,
});

const CandidateMobileDashboard = () => {
  const navigate = useNavigate();
  // TODO: handle error
  const { data, loading, error, refetch } = useGetJobsForCandidateQuery();

  const { filterText, setFilterText, filteredJobs } = useFilterJobsForCandidate(
    data?.jobsForCandidate || []
  );

  if (error) {
    return <div>TODO: handle</div>;
  }

  const onApplyFilters = (appliedFilters: any) => {
    refetch({
      filters: appliedFilters,
    });
  };

  return (
    <main>
      <Alert
        message="You have the ideal candidate but there is no job listed? Leave us your contact! and we will find the best job for you"
        buttonLabel="Let's do it"
        onClick={() => {
          navigate("/endorse");
        }}
      />
      <h3>All job posts</h3>
      {data?.jobsForCandidate ? (
        <>
          <div className="dashboard__filters-wrapper">
            <SearchFilters
              filtersAtom={candidateDashboardFiltersState}
              filterText={filterText}
              setFilterText={setFilterText}
              onApply={onApplyFilters}
              loading={loading}
            />
          </div>
          <JobsForCandidateList jobs={filteredJobs} loading={loading} />
        </>
      ) : (
        <Loading />
      )}
    </main>
  );
};

export default CandidateMobileDashboard;
