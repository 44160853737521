import React from "react";

import {
  ApolloClient,
  ApolloProvider,
  from,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createUploadLink } from "apollo-upload-client";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import { createClient } from "graphql-ws";
import ReactDOM from "react-dom";
import ReactGA from "react-ga4";
import { BrowserRouter as Router } from "react-router-dom";
import { RecoilRoot } from "recoil";

import { ErrorMsg } from "generated/graphql";

import { TOKEN_KEY } from "app-constants";

import AuthProvider from "components/Auth";
import ErrorBoundary from "components/Errors/ErrorBoundary";

import App from "./App";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";

if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_SENTRY_RELEASE &&
  process.env.REACT_APP_SENTRY_DSN
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize("G-PZ18SKK51Q");
}

dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: function (number: any, withoutSuffix: boolean) {
      if (withoutSuffix) {
        return "now";
      }
      return "a few sec";
    },
    m: "a min",
    mm: "%d min",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_GRAPHQL_URI_WS || "ws://localhost:8080/graphql",
    connectionParams: {
      authToken: localStorage.getItem(TOKEN_KEY),
    },
  })
);

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_URI || "http://localhost:8080/graphql",
});

const authLink = setContext((_: any, { headers }) => {
  const token = localStorage.getItem(TOKEN_KEY);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    console.log(graphQLErrors);
    graphQLErrors.forEach(({ message }) => {
      // TODO: handle
      if (message === ErrorMsg.Unauthorized) {
        console.log("unauthenticated");
      }
    });
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  // @ts-ignore
  uploadLink
);

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(splitLink)]),
  cache: new InMemoryCache({
    typePolicies: {
      Application: {
        fields: {
          status: {
            merge: true,
          },
        },
      },
      Query: {
        fields: {
          conversations: {
            merge: (existing, incoming) => {
              return incoming;
            },
          },
        },
      },
    },
  }),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <Router>
      <AuthProvider>
        <ErrorBoundary>
          <RecoilRoot>
            <App />
          </RecoilRoot>
        </ErrorBoundary>
      </AuthProvider>
    </Router>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
