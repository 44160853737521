import { ReactNode } from "react";

type Props = {
  title?: string;
  description?: string;
  extra?: ReactNode;
};

const Text = ({ title, description, extra }: Props): JSX.Element => {
  return (
    <div className="dorse-landing__text">
      {title && <h1>{title}</h1>}
      {description && <div className="description">{description}</div>}
      {extra}
    </div>
  );
};

export default Text;
