import { useEffect } from "react";

import {
  GetNotificationCreatedDocument,
  GetNotificationsQuery,
  useGetNotificationsQuery,
} from "generated/graphql";

import useAuth from "components/Auth/useAuth";

const useNotifications = (
  subscribe?: boolean
): {
  notifications?: GetNotificationsQuery["notifications"];
  loading: boolean;
  unread?: number;
  refetch: VoidFunction;
} => {
  const { token } = useAuth();
  const { data, loading, refetch, subscribeToMore } =
    useGetNotificationsQuery();

  useEffect(() => {
    if (subscribe) {
      subscribeToMore({
        document: GetNotificationCreatedDocument,
        variables: {
          jwtToken: token,
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          // @ts-ignore
          const newNotification = subscriptionData.data.notificationCreated;
          const prevNotifications = prev.notifications || [];
          return Object.assign({}, prev, {
            notifications: [newNotification, ...prevNotifications],
          });
        },
      });
    }
  }, [subscribeToMore, subscribe, token]);

  const unread = data?.notifications?.filter(
    (notification) => !notification?.read
  ).length;

  return { notifications: data?.notifications, loading, unread, refetch };
};

export default useNotifications;
