import React, { FunctionComponent } from "react";

import { Button } from "antd";
import { Bounce, Fade, Slide, Zoom } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";

import endorsed from "images/landing/endorsed.svg";
import how1 from "images/landing/how1.svg";
import how2 from "images/landing/how2.svg";
import how3 from "images/landing/how3.svg";
import how4 from "images/landing/how4.svg";
import profiles from "images/landing/profiles.svg";
import refer from "images/landing/refer.svg";
import stake from "images/landing/stake.svg";
import statistic from "images/landing/statistic.svg";

import Box from "./Box";
// import Button from "./Button";
import Footer from "./Footer";
import Image from "./Image";
import Navbar from "./Navbar";
import Ready from "./Ready";
import Section from "./Section";
import "./style.scss";
import Text from "./Text";

const Landing: FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <div className="dorse-landing__wrapper">
      <Navbar />

      <Section>
        <Text
          title="Endorsed by your network"
          description="Hiring companies gets better insights of the candidates both from their network and third party validators"
          extra={
            <Button
              type="primary"
              className="gradient-background"
              shape="round"
              onClick={() => {
                navigate("/signup");
              }}
            >
              GET STARTED
            </Button>
          }
        />
        <Image src={endorsed} alt="Endorsed" height="100%" width="100%" />
      </Section>

      <main className="dorse-landing__content">
        {/*<Bounce>*/}
        {/*  <Section reversed>*/}
        {/*    <Text*/}
        {/*      title="Better range"*/}
        {/*      description="Hiring companies gets better insights of the candidates both from their network and third party validators"*/}
        {/*      extra={<a href="/">Learn more</a>}*/}
        {/*    />*/}
        {/*    <Image src={range} alt="Better range" width={200} height={200} />*/}
        {/*  </Section>*/}
        {/*</Bounce>*/}

        {/*<Bounce>*/}
        {/*  <Section>*/}
        {/*    <Text*/}
        {/*      title="Less noise"*/}
        {/*      description="Job market becomes more transparent by discouraging low quality applications."*/}
        {/*      extra={<a href="/">Learn more</a>}*/}
        {/*    />*/}
        {/*    <Image src={noise} alt="Better range" width={200} height={200} />*/}
        {/*  </Section>*/}
        {/*</Bounce>*/}

        {/*        <Bounce>*/}
        {/*          <Section reversed>*/}
        {/*            <Text*/}
        {/*              title="Fairness"*/}
        {/*              description="Uncommon profiles can be regarded differently through the help of a referrer.*/}
        {/*"*/}
        {/*              extra={<a href="/">Learn more</a>}*/}
        {/*            />*/}
        {/*            <Image src={fairness} alt="Better range" width={200} height={200} />*/}
        {/*          </Section>*/}
        {/*        </Bounce>*/}

        <Section>3 columns here</Section>

        <Fade>
          <h1 className="centered">Splitting the rewards</h1>
        </Fade>

        <div className="dorse-landing__features">
          <Slide direction="left">
            <Box
              icon={refer}
              title="Endorse people"
              description="Find someone you believe could be the perfect match for a role and make them stand out. We reward referrals highly!"
            />
          </Slide>
          <Slide direction="right">
            <Box
              icon={statistic}
              title="Insights"
              description="Hiring companies get a much better picture of the talent pool: referrals, stakes, a scoring for each application helps them find out who’s the best fit."
            />
          </Slide>
          <Slide direction="left">
            <Box
              icon={profiles}
              title="Profiles"
              description="Candidates get a sign-off bonus when they are hired through Dorse."
            />
          </Slide>
          <Slide direction="right">
            <Box
              icon={stake}
              title="Stake on people"
              description="If you see that a profile is the right one, then stake some $DORSE on it. If you’re right, you can also get a great reward!"
            />
          </Slide>
          <Slide direction="left">
            <Box
              icon={profiles}
              title="Profiles"
              description="A pseudo-Latin text used in web design, layout, and printing in place of things to emphasise layouts for previewing layouts and visual mockups."
            />
          </Slide>
          <Slide direction="right">
            <Box
              icon={stake}
              title="Stake on people"
              description="A pseudo-Latin text used in web design, layout, and printing in place of things to emphasise layouts for previewing layouts and visual mockups."
            />
          </Slide>
        </div>

        <Ready />

        <Fade>
          <h1>What are we bringing into the ecosystem?</h1>
        </Fade>

        <Zoom>
          <Section reversed>
            <Text
              title="For the company"
              description="The company that publishes the role finds out that they are on possesion of a extraordinary amount of data that otherwise would not be disclosed."
            />
            <Image src={how1} alt="How does it work 1" width={300} />
          </Section>
        </Zoom>

        <Zoom>
          <Section>
            <Text
              title="For a candidate"
              description="Candidates can apply to a role, and have someone they trust to make them stand out.
Referrals boosts profiles that otherwise will be overlooked by a typical recruiter."
            />
            <Image src={how2} alt="How does it work 2" width={300} />
          </Section>
        </Zoom>

        <Zoom>
          <Section reversed>
            <Text
              title="We crunch data"
              description="Dorse score measures the match likelihood of a candidate and a job based on current and past data through AI+ML."
            />
            <Image src={how3} alt="How does it work 3" width={300} />
          </Section>
        </Zoom>

        <Zoom>
          <Section>
            <Text
              title="Staking"
              description="Staking distribution gives an idea of how validators feel about a profile. And information about these people is available and relevant."
            />
            <Image src={how4} alt="How does it work 4" width={300} />
          </Section>
        </Zoom>
      </main>

      <Footer />
    </div>
  );
};

export default Landing;
