type Props = {
  icon: string;
  title: string;
  description: string;
};

const Box = ({ icon, title, description }: Props): JSX.Element => {
  return (
    <div className="dorse-landing__box">
      <img src={icon} alt={title} />
      <h2>{title}</h2>
      <div className="description">{description}</div>
    </div>
  );
};

export default Box;
