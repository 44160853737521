import { ReactNode } from "react";

import { BellOutlined } from "@ant-design/icons";
import { Avatar, Tag } from "antd";
import { isScrolled50Atom } from "App";
import classNames from "classnames";
import useNotifications from "hooks/useNotifications";
import usePageTitle from "hooks/usePageTitle";
import useWindowSize from "hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { DEFAULT_USER_PHOTO_URL } from "app-constants";

import { ReactComponent as BackIcon } from "images/icons/back.svg";

import useAuth from "components/Auth/useAuth";

import "./style.scss";

type Props = {
  customTitle?: ReactNode;
};

const DesktopTopBar = ({ customTitle }: Props): JSX.Element => {
  const { userProfile, isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const { unread } = useNotifications();
  const isScrolled = useRecoilValue(isScrolled50Atom);

  const { title, subtitle, setCustomTitle } = usePageTitle();

  const navigateToProfile = () => {
    navigate("/profile");
  };

  if (!isMobile) {
    return (
      <nav
        className={classNames("desktop-topbar__wrapper", {
          "desktop-topbar__wrapper-override": !!customTitle,
          "desktop-topbar__scrolled": isScrolled,
          "desktop-topbar__not-logged-in": !isLoggedIn,
        })}
      >
        <div className="topbar__title">
          {(subtitle || customTitle) && (
            <BackIcon
              onClick={() => {
                setCustomTitle(undefined);
                navigate(-1);
              }}
            />
          )}
          <div className="topbar__title-text">
            {customTitle || subtitle || title}
          </div>
        </div>
        <section className="desktop-topbar__right">
          <>
            {unread !== undefined && isLoggedIn && (
              <Tag
                icon={<BellOutlined />}
                className={classNames("notifications-tag", {
                  "notifications-tag__unread": unread > 0,
                })}
                onClick={() => {
                  navigate("/notifications");
                }}
              >
                {unread}
              </Tag>
            )}
            {userProfile && (
              <>
                <div onClick={navigateToProfile} className="clickable">
                  <Avatar
                    src={userProfile?.photoUrl || DEFAULT_USER_PHOTO_URL}
                    size={40}
                    className="user-avatar"
                  />
                </div>
                <div onClick={navigateToProfile} className="clickable">
                  {userProfile?.name.split(" ")[0]}{" "}
                  {userProfile?.name.split(" ")[1]}
                </div>
              </>
            )}
          </>
        </section>
      </nav>
    );
  }

  return <></>;
};

export default DesktopTopBar;
