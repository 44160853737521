import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import { useLocation } from "react-router-dom";
import { atom, useRecoilState } from "recoil";

import { TabsEnum } from "components/TabBar";

const TITLES: Record<TabsEnum, string> = {
  "": "Home",
  dashboard: "Home",
  add: "Add a job post",
  jobs: "My job posts",
  notifications: "Notifications",
  profile: "Profile",
  "my-applications": "My applications",
  "my-endorsements": "My endorsements",
  chat: "Chat",
  conversation: "Conversation",
};

export const customTitleAtom = atom<string | ReactNode | undefined>({
  key: "customTitle",
  default: undefined,
});

const usePageTitle = (): {
  title: string;
  subtitle: string | undefined;
  customTitle: string | undefined | ReactNode;
  setCustomTitle: Dispatch<SetStateAction<string | ReactNode | undefined>>;
} => {
  const { pathname } = useLocation();
  const [title, setTitle] = useState<string>("");
  const [subtitle, setSubtitle] = useState<string | undefined>(undefined);
  const [customTitle, setCustomTitle] = useRecoilState(customTitleAtom);

  const getSubtitle = (root: TabsEnum, split: string[]): string | undefined => {
    switch (root) {
      case "":
      case "dashboard":
      case "add":
      case "my-applications":
      case "my-endorsements":
      case "notifications":
      case "jobs":
        return undefined;
      case "profile":
        if (split[2]?.toLowerCase() === "settings") {
          return split[2];
        }
        return undefined;
      default:
        return undefined;
    }
  };

  useEffect(() => {
    const split = pathname.split("/");
    const path: TabsEnum = split[1] as TabsEnum;
    setTitle(TITLES[path] || path);
    setSubtitle(getSubtitle(path, split));
  }, [pathname]);

  return { title, subtitle, customTitle, setCustomTitle };
};

export default usePageTitle;
