import { useEffect } from "react";

const useDisablePinchZoomEffect = () => {
  useEffect(() => {
    const disablePinchZoom = (e: any) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };
    document.addEventListener("resize", disablePinchZoom, {
      // passive: false,
    });
    return () => {
      document.removeEventListener("resize", disablePinchZoom);
    };
  }, []);
};

export default useDisablePinchZoomEffect;
