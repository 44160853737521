import { Avatar } from "antd";
import { AutoCenter, Button, Card, Empty, Tag } from "antd-mobile";
import { Link } from "react-router-dom";

import { GetJobsForCandidateQuery } from "generated/graphql";

import { DEFAULT_COMPANY_PHOTO_URL } from "app-constants";

import Loading from "components/Loading";

import { formatNumber, formatRelativeDate } from "utils";

import "./style.scss";

type Props = {
  jobs?: GetJobsForCandidateQuery["jobsForCandidate"];
  loading?: boolean;
  openFilters?: VoidFunction;
  hasFilters?: boolean;
};

const JobsForCandidateList = ({
  jobs,
  loading = false,
  hasFilters = false,
  openFilters,
}: Props): JSX.Element => {
  if (!jobs) {
    return <div>TODO: handle error fetching jobs</div>;
  }
  if (loading) {
    return <Loading />;
  }

  if (jobs.length === 0) {
    return (
      <Empty
        style={{ height: "100%" }}
        description={
          <div>
            <AutoCenter>
              <h3>No jobs found</h3>
            </AutoCenter>
            {hasFilters && openFilters ? (
              <Button color="primary" onClick={openFilters}>
                Try other filters
              </Button>
            ) : null}
          </div>
        }
      />
    );
  }

  // TODO: add filters/sort by
  return (
    <>
      {jobs.map(({ jobAd, application }) => {
        const {
          id,
          title,
          format,
          location,
          currency,
          minSalary,
          maxSalary,
          company,
          date,
        } = jobAd;
        return (
          <Link key={id} to={`/job/${id}`}>
            <Card className="job-card-wrapper">
              <Avatar
                src={company?.photoUrl || DEFAULT_COMPANY_PHOTO_URL}
                size={60}
              />
              <div className="job-card-center">
                <div className="job-card-title">{title}</div>
                <div className="job-card-subtitle">
                  {format.replace("_", " ")}
                </div>
                <div className="job-card-footer">{location}</div>
              </div>
              <div className="job-card-right">
                <Tag round>
                  {currency} {formatNumber(minSalary, true)}-
                  {formatNumber(maxSalary, true)}
                </Tag>
                <div className="job-card-footer">
                  {application ? "applied" : null}
                  {/*{pluralize("candidate", applications?.length || 0, true)}*/}
                </div>
                <div className="job-card-footer">
                  {formatRelativeDate(date)}
                </div>
              </div>
            </Card>
          </Link>
        );
      })}
    </>
  );
};

export default JobsForCandidateList;
